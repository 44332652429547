import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchCustomers } from './CustomersActions';
import CustomerItem from './CustomerItem';
import BackButton from '../../components/Buttons/BackButton';
import classes from './Customers.module.css';

function Customers(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const customers = useSelector((state) => state.customers.customers, shallowEqual);

    useEffect(() => {
        fetchCustomers(dispatch);
    }, [])

    return (<>
        <div className={classes.main}>
            <div className={classes.leftContainer}>
                <div className={classes.backButton}>
                    <BackButton onBack={() => props.history.goBack()} />
                </div>
                <div className={classes.container}>
                    <h2> {t('customers.heading')} </h2>
                </div>
                <div className={classes.usersTable}>
                    <table className='table'>
                        <thead >
                            <tr>
                                <th style={{ "width": "25%" }}>{t('customers.table.id')}</th>
                                <th style={{ "width": "30%" }}>{t('customers.table.owner')}</th>
                                <th style={{ "width": "30%" }}>{t('customers.table.environment')}</th>
                                <th style={{ "width": "15%" }}>{t('customers.table.action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customers.map(e => <CustomerItem customer={e} key={e.id} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>);
}

export default Customers;