import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateConfig } from "./ConfigActions";
import BackButton from '../../components/Buttons/BackButton';
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import ConfigItem from "./ConfigItem";
import ToggleSetting from "./ToggleSetting";
import EditHelpMessage from "./EditHelpMessage";
import Utils from "../../Utils";
import classes from "./Config.module.css";

function Config(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialConfig = useSelector((state) => state.config.config) || {};
  const currentUser = useSelector((state) => state.users.allUsers.currentUser || {});
  const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");

  const [config, setConfig] = useState(initialConfig || {});
  const [showEditHelpMessage, setShowEditHelpMessage] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setConfig(initialConfig);
  }, [initialConfig]);

  function isApplyEnabled() {
    if (!Utils.canEditConfig(permissions)) {
      return false;
    }
    if (config.isAutoPollingOn && isNaN(config.autoPollingIntervalMins)) {
      return false;
    }
    if (config.isAutoPurgeOn && isNaN(config.autoDeleteAfterDays)) {
      return false;
    }
    return true;
  }

  function configChangeHandler(prop, value) {
    setConfig((prevState) => {
      const newConfig = {
        ...prevState
      }
      newConfig[prop] = value;
      return newConfig;
    });
  }

  function updateConfigHandler() {
    let data = {
      unit : config.unit, 
      isAutoPollingOn : config.isAutoPollingOn, 
      autoPollingIntervalMins : config.autoPollingIntervalMins, 
      isAutoPurgeOn : config.isAutoPurgeOn, 
      autoDeleteScheduleAt : config.autoDeleteScheduleAt, 
      autoDeleteAfterDays : config.autoDeleteAfterDays
    };

    if (Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(currentUser)) {
      data.showHelpMessage = config.showHelpMessage;
    }

    updateConfig(dispatch, data).then((response) => {
      if (response && response.data && response.data.success) {
        setShowError(false);
      } else {
        setShowError(true);
      }
    });
  }

  function editHelpMessageCloseHandler() {
    setShowEditHelpMessage(false);
  }

  return (<>
    {showError && Utils.showErrorDialog(t('config.error.updateFailed'), () => setShowError(false))}
    {Utils.isSuperAdmin(currentUser) && showEditHelpMessage && <EditHelpMessage onClose={editHelpMessageCloseHandler}/>}
    <div className={classes.main}>
      <div className={classes.backButton}>
        <BackButton onBack={() => props.history.goBack()} />
      </div>
      <div className={classes.container}>
        <div className={classes.heading}>
          <h2> {t('config.heading')} </h2>
        </div>
        <ConfigItem
          checked={config.isAutoPollingOn}
          onCheckChange={(value) => configChangeHandler("isAutoPollingOn", value)}
          Data={[{
            title: t("configItem.pollingOrder"),
            durationType: t("configItem.duration.mins"),
            value: config.autoPollingIntervalMins,
            type: "number",
            min: 1,
            disabled: !config.isAutoPollingOn,
            onValueChange: (value) => configChangeHandler("autoPollingIntervalMins", value),
          }]}
        />
        <ConfigItem
          checked={config.isAutoPurgeOn}
          onCheckChange={(value) => configChangeHandler("isAutoPurgeOn", value)}
          Data={[{
            title: t("configItem.deleteOrderDays"),
            durationType: t("configItem.duration.days"),
            value: config.autoDeleteAfterDays,
            type: "number",
            min: 1,
            disabled: !config.isAutoPurgeOn,
            onValueChange: (value) => configChangeHandler("autoDeleteAfterDays", value)
          },
          {
            title: t("configItem.deleteOrderTime"),
            value: Utils.getLocalTime(config.autoDeleteScheduleAt),
            type: "time",
            disabled: !config.isAutoPurgeOn,
            onValueChange: (value) => configChangeHandler("autoDeleteScheduleAt", Utils.getUTCTime(value))
          }
          ]}
        />
        <ToggleSetting title={t("configItem.changeUnit")}
          onChange={(value) => configChangeHandler("unit", value ? "inches" : "mm")}
          checkedLabel={t("configItem.unit.inches")}
          uncheckedLabel={t("configItem.unit.mm")}
          checked={config.unit === "inches"} />
        {
          Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(currentUser) && 
          <ToggleSetting title={t("configItem.showHelpMessage")}
            onChange={(value) => configChangeHandler("showHelpMessage", value)}
            checkedLabel={t("configItem.helpMessage.yes")}
            uncheckedLabel={t("configItem.helpMessage.no")}
            checked={config.showHelpMessage}
            onEdit={() => setShowEditHelpMessage(true)}
            editTooltip={t("configItem.tooltip.editHelpMessage")}/>
        }
        <div className={classes.btn}>
          <PrimaryButton onClick={updateConfigHandler} disabled={!isApplyEnabled()} label="Apply" />
        </div>
      </div>
    </div>
  </>
  );
}

export default Config;