import React from "react";
import TextButton from "../Buttons/TextButton";
import Modal from "../Modal/Modal";
import classes from "./Confirm.module.css";

function Confirm(props) {
    return (
        <Modal title={props.title} onClose={props.onClose}>
            <div className={classes.message}>
                <p>{props.message}</p>
            </div>
            <div className={classes.buttons}>
                {props.cancelLabel && <TextButton label={props.cancelLabel.toUpperCase()} onClick={props.onClose} />}
                <TextButton onClick={props.onConfirm} label={props.confirmLabel.toUpperCase()} />
            </div>
        </Modal>
    );
}

export default Confirm;