import React from "react";
import classes from "./OutputSettingContainer.module.css";

function OutputSettingContainer(props) {
    return( 
    <div className={classes.main} style={props.style}>
        {props.children}
    </div>);
}

export default OutputSettingContainer;