import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allOutputChannels: [],
    fetchCurrentOutputChannels: []
};

const outputChannelsSlice = createSlice({
    name: "outputChannels",
    initialState,
    reducers: {
        fetchOutputChannels: (state, action) => {
            state.allOutputChannels = action.payload.outputChannels;
        },
        fetchCurrentOutputChannels: (state, action) => {
            state.fetchCurrentOutputChannels = action.payload.outputChannels;
        },
    },
});

export const outputChannelsActions = outputChannelsSlice.actions;

export default outputChannelsSlice.reducer;
