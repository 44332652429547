import React from "react";

function SecondaryButton(props) {
    return (
        <button type="button" onClick={props.onClick} disabled={props.disabled}
            className={`btn btn-md secondaryBtn`}> {props.label}
        </button>
    );
}

export default SecondaryButton;