import React, { useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Loading from "../../components/Loading/Loading";
import Modal from "../../components/Modal/Modal";
import Password from "../../components/Password/Password";
import Utils from "../../Utils";
import { updateUser } from '../Users/UsersActions';
import classes from "./PasswordManager.module.css";

const PasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'));
function PasswordManager(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState(props.userDetails || {});
    const [passwordScore, setPasswordScore] = useState(0);
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);
    const [showError, setShowError] = useState(false);

    function actionHandler() {

        if (userDetails.password !== userDetails.confirmPassword) {
            setIsPasswordMatch(false);
        }
        else if (passwordScore > 1) {
            let data = { ...userDetails };
            delete data.confirmPassword;
            updateUser(dispatch, data, true).then((response) => {
                if (response && response.data && response.data.success) {
                    setShowError(false);
                    props.responseHandler(true, t('successCard.message.passwordUpdated'));
                    props.passwordManagerCloseHandler();
                }
                else {
                    setShowError(true);
                    props.responseHandler(false);
                }

            })
        }
        else {
            setIsPasswordMatch(true);
        }
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    return (<Modal onClose={() => props.passwordManagerCloseHandler()} title={t('passwordManager.heading')}>
        {showError && Utils.showErrorDialog(t('users.error.updatePasswordFailed'), closeErrorHandler)
        }
        <div className={classes.main}>
            <label htmlFor="existingPassword">{t('passwordManager.existingPassword')}</label>
            <Password id="existingPassword" autoFocus={true}
                width="12.5rem" height="2rem"
                onChange={(e) => setUserDetails((prevState) => {
                    return {
                        ...prevState,
                        existingPassword: e.target.value
                    }
                })} togglePasswordVisibility={true} />
            <label htmlFor="newpaswword">{t('passwordManager.newPassword')}</label>
            <Password id="newpaswword"
                width="12.5rem" height="2rem"
                onChange={(e) => setUserDetails((prevState) => {
                    return {
                        ...prevState,
                        password: e.target.value
                    }
                })} togglePasswordVisibility={true} />
            <div className={classes.container}>
                <Suspense fallback={<Loading />}>
                    <PasswordStrengthBar password={userDetails.password} scoreWords={[t('passwordStrength.weak'), t('passwordStrength.weak'), t('passwordStrength.okay'), t('passwordStrength.good'), t('passwordStrength.strong')]} minLength={4} shortScoreWord={t('passwordStrength.short')} onChangeScore={(score, feedback) => {
                        if (score) {
                            setPasswordScore(score);
                        }
                    }} />
                </Suspense>
            </div>
            <label htmlFor="confirmPaswword">{t('passwordManager.confirmPassword')}</label>
            <Password id="confirmPaswword"
                width="12.5rem" height="2rem"
                onChange={(e) => setUserDetails((prevState) => {
                    return {
                        ...prevState,
                        confirmPassword: e.target.value
                    }
                })} togglePasswordVisibility={true} />
            {!isPasswordMatch && <div style={{ "color": "red" }} ><p> {t('password.misMatchMsg')}</p></div>}
            <div className={classes.actions}>
                <SecondaryButton onClick={() => props.passwordManagerCloseHandler()} label={t('button.cancel')} />
                <PrimaryButton onClick={actionHandler} label={t('button.change')} />
            </div>
        </div>
    </Modal>
    )
}

export default PasswordManager;