import browserHistory from "../../../history.js";
import axios from "axios";

export function loginUser(creds, type) {
  return axios({
    url: "/pax/user/login",
    method: "post",
    data: creds,
    responseType: "json",
    headers: {
      Type: type
    }
  })
    .then((response) => {
      if (response.data.success) {
        localStorage.setItem("isLoggedIn", true);
        let url,
            resourceName = window.getResourceName(),
            environment = resourceName || "root";
        if (resourceName) {
          url = `/${resourceName}/orders`;
        } else {
          url = '/customers';
        }
        let dataLayer = window.dataLayer;
        if (dataLayer) {
          dataLayer.push({
            event: 'login',
            userId: creds.userId,
            type, environment
           });
        }
        browserHistory.push(url);
        browserHistory.go();
      }
      return response;
    }).catch((error) => {
      return error;
    });
}

export function loginUsingToken(token, type) {
  return axios({
    url: "/pax/user/token",
    method: "put",
    headers: {
      Authorization: token,
      Type: type,
      Location: 'remote'
    }
  })
    .then((response) => {
      let data = response.data;
      if (data.success) {
        localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("type", type);
        let url = '/';
        if (type === 'b2c') {
          if (!data.resourceName) {
            url += "create-environment";
          } else {
            url += data.resourceName;
          }
        }
        browserHistory.push(url);
        browserHistory.go();
      }
      return response;
    }).catch((error) => {
      return error;
    });
}

export function logoutUser(skipRedirect) {
  return axios({
    url: "/pax/user/logout",
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        localStorage.removeItem("isLoggedIn");
        if (skipRedirect !== true) {
          let url = '/';
          // if (localStorage.getItem("type") !== 'b2b') {
          //   url += window.resourceName || '';
          // }
          browserHistory.push(url);
          browserHistory.go();
        }
      }
      return response;
    }).catch((error) => {
      return error;
    });
}
