import axios from '../../axios';
import { ordersActions } from './OrdersSlice';
import { outputPresetsActions } from './OutputPresetSlice';
import Utils from '../../Utils';


export function getAllOrders(dispatch) {
    return axios({
        url: '/pax/orders?type=active',
        method: 'get',
        responseType: 'json'
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            let orders = data.orders || [];
            dispatch(ordersActions.fetchOrders({ orders: orders || [] }));
        }
    }).catch(function (error) {
        console.log(error);
    });
}

export function updateOrder(dispatch, data) {
    return axios({
        url: '/pax/orders',
        method: "put",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                dispatch(ordersActions.updateOrder({ order: data }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function deleteOrder(dispatch, data) {
    return axios({
        url: '/pax/orders',
        method: "delete",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                dispatch(ordersActions.deleteOrder({ order: data }));
            };
            return response;
        }).catch((error) => {
            return error;
        });
}

export function addOrder(dispatch, data) {
    return axios({
        url: '/pax/orders',
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                let newdata = data;
                newdata.id = response.data.id;
                dispatch(ordersActions.createOrder({ order: newdata }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function printOrders(dispatch, data) {
    return axios({
        url: '/pax/print/orders',
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                let dataLayer = window.dataLayer;
                if (dataLayer) {
                    dataLayer.push({
                        event: 'jobProcessed',
                        environment: window.getResourceName(),
                        tableName: data.table?.name
                    });
                }
                dispatch(ordersActions.printOrder({ orders: data.printData.assignedOrders }));
                dispatch(outputPresetsActions.removeTableData({ presetId: data.presetId }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function printJobSheet(data) {
    return axios({
        url: '/pax/print/jobsheet',
        method: "post",
        data: data,
        responseType: "blob",
    })
        .then((response) => {
            let a = document.createElement('a');
            let url = window.URL.createObjectURL(response.data);
            a.href = url;
            let fileName = "JobSheet_" + Utils.getDateString(new Date(), true) + ".pdf"
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            return response;
        }).catch((error) => {
            return error;
        });
}

export function printJobLabels(data) {
    return axios({
        url: '/pax/print/joblabels',
        method: "post",
        data: data,
        responseType: "blob",
    })
        .then((response) => {
            let a = document.createElement('a');
            let url = window.URL.createObjectURL(response.data);
            a.href = url;
            let fileName = "JobLabels_" + Utils.getDateString(new Date(), true) + ".pdf"
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            return response;
        }).catch((error) => {
            return error;
        });
}

export function activateOrder(dispatch, data) {
    return axios({
        url: '/pax/orders/activate',
        method: "put",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                dispatch(ordersActions.deleteHistoryOrder({ order: data }));
                dispatch(ordersActions.activateOrder({ order: data }));
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function getHistoryOrders(dispatch) {
    return axios({
        url: '/pax/orders?type=history',
        method: 'get',
        responseType: 'json'
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            let orders = data.orders || [];
            dispatch(ordersActions.fetchHistoryOrders({ orders: orders || [] }));
        }
    }).catch(function (error) {
        console.log(error);
    });
}

export function reprocessOrders() {
    return axios({
        url: '/pax/orders/reprocess',
        method: "put",
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                return true;
            }
            return response;
        }).catch((error) => {
            return error;
        });
}
