import React, { useState } from "react";
import classes from "./InlineTextEditor.module.css";

function InlineTextEditor(props) {
    const [value, setValue] = useState(props.value);

    function valueChangeHandler(e) {
        const newValue = e.target.value;
        setValue(newValue);
        props.onChange && props.onChange(newValue.trim());
    }

    return (
        <div className={classes.editValue} onClick={(e) => e.stopPropagation()} style={props.style}>
            <input type="text" name="value" onChange={valueChangeHandler} value={value} autoFocus={true} autoComplete="off" />
            <button type="button" onClick={(e) => {e.stopPropagation(); props.onSave(value.trim());}} className={classes.saveBtn} disabled={!props.saveEnabled}>✓</button>
            <button type="button" onClick={props.onClose} className={classes.saveBtn}>x</button>
        </div>
    )
}

export default InlineTextEditor;