import React from "react";
import i18n from "../i18n/i18n";
import MessageDialog from "./components/MessageDialog/MessageDialog";
import Consts from "./Consts";
import nextId from "react-id-generator";

const comparatorKeys = ["colorConfig", "qualityLevel", "mediaType", "printerType"];
function orderValue(a, keys) {
    let x = 0; 
    keys.forEach((i, idx) => {
        if (a[i]) {
            x |= 1 << idx;
        }
    })
    return x;
}

function getPresetKeysSequence(preset) {
    let has = [], notHas = [], filterParms = preset._params;
    comparatorKeys.forEach(k => {
        (filterParms[k] ? has : notHas).push(k);
    })
    return has.concat(notHas);
}

function orderSorter(a, b, keys) {
    return Math.abs(orderValue(b, keys) - orderValue(a, keys))
}

const Utils = {
    containsIgnoreCase: (string, search) => {
        return string.search(new RegExp(search, "i")) !== -1;
    },

    getSlotShapeMarkup: (slot, fillColor, strokeColor, strokeWeight) => {
        if (!slot)
            return '';

        const slotType = slot.type || "RECT";
        if (slotType === "RECT") {
            return <rect x={slot.x} y={slot.y} width={slot.width} height={slot.height} fill={fillColor} stroke={strokeColor} strokeWidth={strokeWeight} />;
        }

        if (slotType === "CIRCLE") {
            return <circle cx={slot.cx} cy={slot.cy} r={slot.r} fill={fillColor} stroke={strokeColor} strokeWidth={strokeWeight} />;
        }

        if (slotType === "ELLIPSE") {
            return <ellipse cx={slot.cx} cy={slot.cy} rx={slot.rx} ry={slot.ry} fill={fillColor} stroke={strokeColor} strokeWidth={strokeWeight} />;
        }

        if (slotType === "PATH") {
            let d = slot.path.map((pathItem) => {
                return pathItem.join(' ');
            }).join(' ');
            return <path d={d} fill={fillColor} stroke={strokeColor} strokeWidth={strokeWeight} />;
        }
    },

    getOriginMarkerMarkup: (scaling) => {
        return (
            <g transform={`scale(${-1 / scaling})`}>
                <circle cx={0} cy={0} r="5" fill="red" />
                <defs>
                    <marker id="triangle" viewBox="0 0 10 10"
                        refX="1" refY="5"
                        markerUnits="strokeWidth"
                        markerWidth="10" markerHeight="10"
                        orient="auto">
                        <path d="M 0 0 L 10 5 L 0 10 z" fill="#f00" />
                    </marker>
                </defs>
                <line fill="none" stroke="red" x1={0} y1={0} x2={0} y2={-20}
                    markerEnd="url(#triangle)" />
                <line fill="none" stroke="red" x1={0} y1={0} x2={-20} y2={0}
                    markerEnd="url(#triangle)" />
                <text x={-40} y={0} alignmentBaseline="middle" textAnchor="middle" fontSize="0.75rem" fill="#000">X</text>
                <text x={0} y={-40} alignmentBaseline="middle" textAnchor="middle" fontSize="0.75rem" fill="#000">Y</text>
            </g>
        );
    },

    checkOverlap: (rects) => {
        if (rects.length === 0) {
            return false;
        }

        // Map to ltrb format.
        rects = rects.map((rect) => {
            return {
                left: rect.x,
                top: rect.y,
                right: rect.x + rect.width,
                bottom: rect.y + rect.height
            };
        });

        const rectangles = [];
        rectangles.push(rects[0]);

        function rectsIntersect(r1, r2) {
            return !(r1.right <= r2.left || r1.left >= r2.right || r1.bottom <= r2.top || r1.top >= r2.bottom);
        }

        // Check overlap among rects.
        function willOverlap(rect) {
            let isOverlapping = false;
            for (let i = 0; i < rectangles.length; i++) {
                if (rectsIntersect(rect, rectangles[i])) {
                    isOverlapping = true;
                    break;
                }
            }
            return isOverlapping;
        }

        let isOverlap = false;
        for (let j = 1; j < rects.length; j++) {
            if (willOverlap(rects[j])) {
                isOverlap = true;
                break;
            }
            rectangles.push(rects[j]);
        }
        return isOverlap;
    },

    overlapIndexes: (rects, width, height) => {
        const overlap = new Set();
        if (rects.length === 0) {
            return overlap;
        }

        // Map to ltrb format.
        rects = rects.map((rect) => {
            return {
                left: rect.x,
                top: rect.y,
                right: rect.x + rect.width,
                bottom: rect.y + rect.height
            };
        });

        const rectangles = [];
        rectangles.push(rects[0]);

        function rectsIntersect(r1, r2) {
            return !(r1.right <= r2.left || r1.left >= r2.right || r1.bottom <= r2.top || r1.top >= r2.bottom);
        }

        // Check overlap among rects.
        function willOverlap(rect) {
            let isOverlapping = false;
            for (let i = 0; i < rectangles.length; i++) {
                if (rectsIntersect(rect, rectangles[i])) {
                    overlap.add(i);
                    isOverlapping = true;
                }
            }
            return isOverlapping;
        }

        for (let j = 1; j < rects.length; j++) {
            if (willOverlap(rects[j])) {
                overlap.add(j);
            }
            rectangles.push(rects[j]);
        }

        // Check with bounds.
        for (let j = 0; j < rects.length; j++) {
            const rect = rects[j];
            if (rect.left < 0 || rect.top < 0 || rect.right > width || rect.bottom > height) {
                overlap.add(j);
            }
        }
        return overlap;
    },

    outsideBounds: (rects, bounds) => {
        let isOutside = false;
        for (let i = 0; i < rects.length; i++) {
            const rect = rects[i];
            if (rect.x < bounds.x ||
                rect.y < bounds.y ||
                rect.x + rect.width > bounds.x + bounds.width ||
                rect.y + rect.height > bounds.y + bounds.height) {
                isOutside = true;
                break;
            }
        }
        return isOutside;
    },

    isImageBoxInsideSlot: (slot) => {
        const imageBox = slot.imageBox;
        if (!imageBox)
            return;
        if (imageBox.x + imageBox.width <= slot.width &&
            imageBox.y + imageBox.height <= slot.height) {
            return true;
        }
        return false;
    },

    addMultipleSlots: (existingSlots, width, height, slotWidth, slotHeight, marginX, marginY, imageBoxX, imageBoxY, imageBoxWidth, imageBoxHeight, rotation, mirror, scale, placement) => {
        let slots = [];
        let x, y;
        let existingMaxY = 0;

        function pushSlot() {
            let obj = {
                x: x,
                y: y,
                width: slotWidth,
                height: slotHeight,
                imageBox: {
                    x: imageBoxX,
                    y: imageBoxY,
                    width: imageBoxWidth,
                    height: imageBoxHeight,
                    rotation: rotation,
                    mirror: mirror,
                    scale: scale,
                    placement: placement
                },
                id: nextId()
            }
            slots.push(obj);
        }

        if (existingSlots.length === 1) {
            x = existingSlots[0].x + existingSlots[0].width + marginX;
            y = existingSlots[0].y;
            existingMaxY = existingSlots[0].y + existingSlots[0].height;

            if (y + slotHeight + marginY <= height) {
                let noOfSlotsInCol = parseInt((width - x) / (slotWidth + marginX));
                let noOfSlotsInRow = parseInt((height - y) / (slotHeight + marginY));
                let noOfSlotsInRow1 = Math.ceil(existingSlots[0].height / (slotHeight + marginY));
                noOfSlotsInRow = Math.min(noOfSlotsInRow, noOfSlotsInRow1);
                if (noOfSlotsInCol > 0) {
                    existingMaxY = Math.max(existingMaxY, y + noOfSlotsInRow * (slotHeight + marginY) - marginY);
                    for (let i = 0; i < noOfSlotsInRow; i++) {
                        x = existingSlots[0].x + existingSlots[0].width + marginX;
                        for (let i = 0; i < noOfSlotsInCol; i++) {
                            pushSlot();
                            x += slotWidth + marginX;
                        }
                        y += slotHeight + marginY;
                    }
                }
            }
        }

        x = marginX;
        y = marginY + existingMaxY;
        let numRows = 0, numCols = 0;
        numCols = parseInt((width - marginX) / (slotWidth + marginX));
        numRows = parseInt((height - marginY - existingMaxY) / (slotHeight + marginY));

        for (let i = 0; i < numRows; i++) {
            for (let j = 0; j < numCols; j++) {
                pushSlot();
                x += slotWidth + marginX;
            }
            x = marginX;
            y += slotHeight + marginY;
        }
        return slots;
    },

    showErrorDialog: (errorMessage, closeHandler) => {
        return (<MessageDialog message={errorMessage} title={i18n.t('label.error')} btnLabel={i18n.t('button.ok')}
            onClose={closeHandler} width="15rem" height="5rem" />
        );
    },

    expandTableDataWithJigDetails: (table, allJigs) => {
        let jigsData = new Map();
        allJigs.forEach((jig) => {
            jigsData.set(jig.id, {
                name: jig.name,
                width: jig.width,
                height: jig.height,
                slots: jig.slots
            });
        });

        let jigs = [];
        table.jigs.forEach((jig) => {
            let jigData = jigsData.get(jig.id);
            if (jigData) {
                let jigCopy = {
                    ...jig,
                    name: jigData.name,
                    width: jigData.width,
                    height: jigData.height,
                    slots: jigData.slots
                }
                jigs.push(jigCopy);
            }
        });

        let tableCopy = {
            ...table,
            jigs
        };

        return tableCopy;
    },

    checkOrderStatus: (orderAssignedData) => {
        let len = 0;
        let count = 0;
        let status = "EMPTY";
        orderAssignedData.jigs.forEach((jig) => {
            const slots = jig.slots;
            len += slots.length;
            slots.forEach((slot) => {
                if (slot.isBooked) {
                    count++;
                }
            });
        });
        if (count > 0 && len === count) {
            status = "FULL";
        }
        else if (count > 0) {
            status = "PARTIAL"
        }
        return status;
    },

    assignOrdersToSlots: (orders, tableData, preset, assignOneOrder) => {
        let isOrderAssigned = false;

        tableData.orders = [];
        const assignedOrdersMap = new Map();

        if (tableData.assignedOrders) {
            tableData.assignedOrders.forEach((assignedOrder) => {
                assignedOrdersMap.set(assignedOrder.orderId + assignedOrder.jobId + assignedOrder.frameIndex, assignedOrder);
            });
        }

        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            let filterParms = preset._params,
                supported = true;
            for (let key of comparatorKeys) {
                if (filterParms[key] && order[key] && filterParms[key] !== order[key]) {
                    supported = false;
                    break;
                }
            }

            if (order.pending && supported) {
                let newAssigned = 0;
                const jigs = tableData.jigs;
                for (let j = 0; j < jigs.length; j++) {
                    const jig = jigs[j];
                    if (jig.name === order.jig) {
                        const slots = jig.slots;
                        for (let k = 0; k < slots.length; k++) {
                            const slot = slots[k];
                            if (!slot.isBooked && order.pending) {
                                let imageBox = { ...slot.imageBox };
                                slot.isBooked = true;
                                imageBox.orderThumbnailPath = order.thumbnailPath;
                                imageBox.orderFilePath = order.filePath;
                                imageBox.orderFileType = order.fileType;
                                imageBox.orderPhysicalWidth = order.physicalWidth;
                                imageBox.orderPhysicalHeight = order.physicalHeight;
                                slot.orderId = order.orderId;
                                slot.jobId = order.jobId;
                                order.pending--;
                                order.assigned++;
                                slot.imageBox = imageBox;
                                isOrderAssigned = true;
                                newAssigned++;
                                if (assignOneOrder) {
                                    break;
                                }
                            }
                        }
                    }
                    if ((assignOneOrder && isOrderAssigned) || order.pending === 0) {
                        break;
                    }
                }
                if (isOrderAssigned && order.assigned) {
                    let obj = {
                        orderId: order.orderId,
                        jobId: order.jobId,
                        frameIndex: order.frameIndex,
                        pending: order.pending,
                        assigned: order.assigned
                    }
                    tableData.orders.push(obj);
                }
                if (newAssigned) {
                    const orderKey = order.orderId + order.jobId + order.frameIndex;
                    let assignedOrderData = assignedOrdersMap.get(orderKey);
                    if (assignedOrderData) {
                        assignedOrderData.assigned += newAssigned;
                    } else {
                        let assignedOrderObj = {
                            orderId: order.orderId,
                            jobId: order.jobId,
                            frameIndex: order.frameIndex,
                            assigned: newAssigned,
                            copies: order.copies,
                            externalOrderId: order.externalOrderId,
                            dealerOrderId: order.dealerOrderId,
                            frameId: order.frameId,
                            date: order.date,
                            filePath: order.filePath,
                            fileType: order.fileType,
                            customerInfo: order.customerInfo,
                            mediaSize: order.mediaSize,
                            mediaName: order.mediaName,
                            mediaColor: order.mediaColor,
                        }
                        assignedOrdersMap.set(orderKey, assignedOrderObj);
                    }
                }
            }
            if (assignOneOrder && isOrderAssigned) {
                break;
            }
        }
        tableData.assignedOrders = Array.from(assignedOrdersMap.values());
        if (isOrderAssigned) {
            tableData.isOrderAssigned = true;
        }
        return tableData;
    },

    assignOrdersToPreset: (order, tableData, preset, assignOneOrder) => {
        const orders = [JSON.parse(JSON.stringify(order))];
        if (tableData) {
            let orderData = [];
            const data = JSON.parse(JSON.stringify(tableData))
            const newData = Utils.assignOrdersToSlots(orders, data, preset, assignOneOrder);
            const status = Utils.checkOrderStatus(newData);
            newData.status = status;
            orderData = newData.orders;
            delete newData.orders;
            return [newData, orderData];
        } else {
            return [undefined, undefined];
        }
    },

    assignOrdersToAllPresets: (allOrders, tablesData, presets) => {
        const orders = JSON.parse(JSON.stringify(allOrders));
        let orderData = [];
        const updateOrdersMap = new Map();
        const assignedData = tablesData.map((tableData, idx) => {
            if (tableData) {
                const data = JSON.parse(JSON.stringify(tableData))
                
                const keySequence = getPresetKeysSequence(presets[idx]);
                orders.sort((a, b) => orderSorter(a, b, keySequence));

                const newData = Utils.assignOrdersToSlots(orders, data, presets[idx], false);
                newData.orders.forEach((item) => {
                    updateOrdersMap.set(item.orderId + item.jobId + item.frameIndex, item);
                });
                delete newData.orders;
                const status = Utils.checkOrderStatus(newData);
                newData.status = status;
                return newData;
            }
            return undefined;
        });
        orderData = Array.from(updateOrdersMap.values());
        return [assignedData, orderData];
    },

    getCopyName: (allNames, name) => {
        // Already unique.
        if (!allNames.get(name)) {
            return name;
        }

        if (allNames.get(name + " copy")) {
            // Try generating copy (1), copy (2) etc.
            let i = 1;
            while (allNames.get(name + " copy (" + i + ")")) {
                i++;
            }
            return name + " copy (" + i + ")";
        }
        else {
            return (name + " copy");
        }
    },

    inputPrevent: (e) => {
        const invalidChars = [
            "-",
            "+",
            "e",
        ];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    },

    readFileAsText: (file) => {
        return new Promise(function (acc, rej) {
            let reader = new FileReader();
            reader.onload = function () {
                acc(reader.result);
            };
            reader.onerror = function (e) {
                rej('failed to load file');
            };
            reader.readAsText(file);
        });
    },

    validateEmail: (email, users) => {
        let obj = {
            valid: true,
            message: ""
        };

        if (users && users.length > 0) {
            for (let ind = 0; ind < users.length; ind++) {
                if (users[ind].userId && users[ind].userId.toLowerCase() === email.toLowerCase()) {
                    obj.valid = false;
                    obj.message = i18n.t('email.alreadyExistsMsg');
                    return obj;
                }
            }
        }

        let validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email && !email.match(validEmail)) {
            obj.valid = false;
            obj.message = i18n.t('email.invalidMsg');
            return obj;
        }
        return obj;
    },

    checkViewPermission: (permissions, name) => {
        const permissionsList = Utils.getArray(permissions);
        const viewName = Utils.getPermissionViewName(name);
        const editName = Utils.getPermissionEditName(name);
        const result = permissionsList.includes(viewName) || permissionsList.includes(editName);
        return result;
    },

    checkEditPermission: (permissions, name) => {
        const permissionsList = Utils.getArray(permissions);
        const editName = Utils.getPermissionEditName(name);
        const result = permissionsList.includes(editName);
        return result;
    },

    canViewJigs: (permissions) => Utils.checkViewPermission(permissions, Consts.PERMISSIONS_JIG),

    canEditJigs: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_JIG),

    canViewTables: (permissions) => Utils.checkViewPermission(permissions, Consts.PERMISSIONS_PRINT_LAYOUT),

    canEditTables: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_PRINT_LAYOUT),

    canViewAutomationRules: (permissions) => Utils.checkViewPermission(permissions, Consts.PERMISSIONS_AUTOMATION_RULES),

    canEditAutomationRules: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_AUTOMATION_RULES),

    canManageUsers: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_USER_MANAGEMENT),

    canViewMasters: (permissions) => Utils.checkViewPermission(permissions, Consts.PERMISSIONS_MASTERS),

    canEditMasters: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_MASTERS),
    
    canViewInputSettings: (permissions) => Utils.checkViewPermission(permissions, Consts.PERMISSIONS_INPUT_SETTINGS),

    canEditInputSettings: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_INPUT_SETTINGS),

    canViewOutputSettings: (permissions) => Utils.checkViewPermission(permissions, Consts.PERMISSIONS_OUTPUT_SETTINGS),

    canEditOutputSettings: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_OUTPUT_SETTINGS),

    canViewConfig: (permissions) => Utils.checkViewPermission(permissions, Consts.PERMISSIONS_CONFIGURATION),

    canEditConfig: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_CONFIGURATION),

    canDeleteOrders: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_DELETE_ORDERS),

    canChangeOrderJig: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_CHANGE_ORDER_JIG),

    canDownload: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_DOWNLOADS),
    
    canAssignUsers: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_CHANGE_ORDER_ASSIGNEE),

    canPrintOrder: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_PRINT_ORDER),

    canChangeOutputPreset: (permissions) => Utils.checkEditPermission(permissions, Consts.PERMISSIONS_OUTPUT_PRESET),

    getStringFromKey: (key) => {
        return i18n.t(key);
    },

    getRips: (outputChannels, allPrinters) => {
        const printersMap = {};
        allPrinters.forEach((printer) => {
            const printerKey = printer.modelName + "_" + printer.nickName;
            const printerObj = {
                modelName: printer.modelName,
                nickName: printer.nickName,
                printerType: printer.hasOwnProperty("printerType") ? printer["printerType"] : Consts.DEFAULT_PRINTER_TYPE,
                outputRotation: printer.hasOwnProperty("outputRotation") ? printer["outputRotation"] : Consts.DEFAULT_OUTPUT_ROTATION,
                referenceZeroPointAsLeft: printer.hasOwnProperty("referenceZeroPointAsLeft") ? printer["referenceZeroPointAsLeft"] : Consts.DEFAULT_REFERENCE_ZERO_POINT_AS_LEFT,
                autoCropping: printer.hasOwnProperty("autoCropping") ? printer["autoCropping"] : Consts.DEFAULT_AUTO_CROPPING,
                printerSettingFiles: printer.hasOwnProperty("printerSettingFiles") ? printer["printerSettingFiles"] : Consts.DEFAULT_PRINTER_SETTING_FILES
            }
            printersMap[printerKey] = printerObj;
        });

        const rips = {};
        const versaWorksPrinters = {};
        outputChannels.forEach((channel) => {
            let printer;
            let ripName = channel.ripName;

            if (ripName === Consts.RIP_VERSAWORKS) {
                let printerKey = channel.modelName + "_" + channel.nickName;
                if (!versaWorksPrinters.hasOwnProperty(printerKey)) {
                    printer = {
                        modelName: channel.modelName,
                        nickName: channel.nickName,
                        printerType: Consts.DEFAULT_PRINTER_TYPE,
                        outputRotation: Consts.DEFAULT_OUTPUT_ROTATION,
                        referenceZeroPointAsLeft: Consts.DEFAULT_REFERENCE_ZERO_POINT_AS_LEFT,
                        autoCropping: Consts.DEFAULT_AUTO_CROPPING,
                        printerSettingFiles: Consts.DEFAULT_PRINTER_SETTING_FILES
                    }

                    const channelProperties = [
                        'printerType', 
                        'outputRotation', 
                        'referenceZeroPointAsLeft', 
                        'autoCropping', 
                        'printerSettingFiles'
                    ];

                    channelProperties.forEach((property) => {
                        if (channel.hasOwnProperty(property)) {
                            printer[property] = channel[property];
                        } else if (printersMap.hasOwnProperty(printerKey)) {
                            const matchPrinter = printersMap[printerKey];
                            printer[property] = matchPrinter[property];
                        }
                    });
                    
                    versaWorksPrinters[printerKey] = true;
                }
            } else if (ripName === Consts.RIP_ERGOSOFT) {
                // Only hotfolders for Ergosoft at this point.
                printer = {
                    hotFolderPath: channel.hotFolderPath
                };
            } else if (ripName === Consts.RIP_HOTFOLDER) {
                printer = {
                    hotFolderName: channel.hotFolderName,
                    hotFolderPath: channel.hotFolderPath,
                    hostName: channel.hostName, 
                    printerType: channel.hasOwnProperty("printerType") ? channel["printerType"] : Consts.DEFAULT_PRINTER_TYPE, 
                    outputRotation: channel.hasOwnProperty("outputRotation") ? channel["outputRotation"] : Consts.DEFAULT_OUTPUT_ROTATION, 
                    referenceZeroPointAsLeft: channel.hasOwnProperty("referenceZeroPointAsLeft") ? channel["referenceZeroPointAsLeft"] : Consts.DEFAULT_REFERENCE_ZERO_POINT_AS_LEFT, 
                    autoCropping: channel.hasOwnProperty("autoCropping") ? channel["autoCropping"] : Consts.DEFAULT_AUTO_CROPPING
                };
            }

            if (printer) {
                if (rips.hasOwnProperty(ripName)) {
                    rips[ripName].push(printer);
                } else {
                    rips[ripName] = [printer];
                }
            }
        });
        return rips;
    },

    convertUnitsToMM: (value, unit) => {
        if (unit === "mm") {
            return value;
        } else {
            return value * 25.4;
        }
    },

    convertMMToUnits: (value, unit) => {
        if (unit === "mm") {
            return value;
        } else {
            return value / 25.4;
        }
    },

    appendUnits: (str, unit) => {
        if (unit === "mm") {
            return str + " (mm)";
        }
        else {
            return str + " (in)";
        }
    },

    formatMMToUnits: (value, unit) => {
        if (isNaN(value)) return value;
        let valueUnits = Utils.convertMMToUnits(value, unit);
        if (unit === "mm") {
            return Number(valueUnits).toFixed(2);
        } else {
            return Number(valueUnits).toFixed(4);
        }
    },

    formatMMSizeToUnits: (width, height, unit) => {
        if (isNaN(width) || isNaN(height)) {
            return '- x -';
        } else if (unit === "mm") {
            return width.toFixed(2) + ' mm x ' + height.toFixed(2) + ' mm';
        } else {
            return Utils.convertMMToUnits(width).toFixed(4) + ' in x ' + Utils.convertMMToUnits(height).toFixed(4) + ' in';
        }
    },

    getTablesUsingJig: (allTables, jigId) => {
        const tablesUsingJig = [];
        for (let i = 0; i < allTables.length; i++) {
            const jigs = allTables[i].jigs;
            for (let j = 0; j < jigs.length; j++) {
                const jig = jigs[j];
                if (jig.id === jigId) {
                    tablesUsingJig.push(allTables[i]);
                    break;
                }
            }
        }
        return tablesUsingJig;
    },

    getMaxJigOrTableSize: (unit) => {
        const maxSizeInInches = 200.0;
        if (!unit || unit === "mm") return maxSizeInInches * 25.4;
        return maxSizeInInches;
    },

    getLocalTimezoneOffset: () => {
        // Get local timezone offset from UTC.
        return new Date().getTimezoneOffset();
    },

    getDateString: (date, fullDateString) => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getYear() + 1900;
        let hh = date.getHours();
        let min = date.getMinutes();
        let ss = date.getSeconds();

        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        if (hh < 10) {
            hh = "0" + hh;
        }
        if (min < 10) {
            min = "0" + min;
        }
        if (ss < 10) {
            ss = "0" + ss;
        }
        if (fullDateString) {
            return dd + "_" + mm + "_" + yyyy + "_" + hh + "_" + min + "_" + ss;
        }

        return dd + "-" + mm + "-" + yyyy + " " + hh + ":" + min;
    },

    getLocalDate: (serverDate) => {
        // Get the server date as local formatted date.
        let localDate = new Date(serverDate["$date"]);
        return Utils.getDateString(localDate);
    },

    timeWithOffset: (timeInMinutes, offset) => {
        timeInMinutes = parseInt(timeInMinutes);
        offset = parseInt(offset);
        timeInMinutes += offset;
        if (timeInMinutes < 0) {
            timeInMinutes += 1440;
        }
        timeInMinutes %= 1440;

        return timeInMinutes;
    },

    convertTimeInMinutesToHHMM: (timeInMinutes) => {
        if (!timeInMinutes) return "00:00";

        let hours = parseInt(timeInMinutes / 60);
        let minutes = parseInt(timeInMinutes % 60);

        if (hours < 10) {
            hours = "0" + hours;
        }

        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        const timeInHHMM = hours + ":" + minutes;

        return timeInHHMM;
    },

    convertTimeInHHMMToMinutes: (timeInHHMM) => {
        if (!timeInHHMM || !timeInHHMM.includes(':')) {
            return "";
        }
        const array = timeInHHMM.split(':');
        let hours = array[0];
        let minutes = array[1];
        hours = parseInt(hours);
        minutes = parseInt(minutes);

        const timeInMinutes = (hours * 60) + minutes;
        return timeInMinutes;
    },

    getLocalTime: (timeInMinutes) => {
        let offset = Utils.getLocalTimezoneOffset();
        const time = Utils.timeWithOffset(timeInMinutes, -offset);

        return Utils.convertTimeInMinutesToHHMM(time);
    },

    getUTCTime: (timeInHHMM) => {
        let timeInMinutes = Utils.convertTimeInHHMMToMinutes(timeInHHMM);
        let offset = Utils.getLocalTimezoneOffset();
        return Utils.timeWithOffset(timeInMinutes, offset);
    },

    checkAvailableSlotForOrder: (order, table) => {
        if (!order.jig || !table) return false;
        if (order.pending === 0) return false; // No pending quantity
        if (table.status && table.status === "FULL") return false;
        const ans = (table.jigs.some((jig) => {
            if (order.jig !== jig.name) {
                return false;
            } else {
                return jig.slots.some((slot) => {
                    return !slot.isBooked;
                });
            }
        }));
        return ans;
    },

    getSystemParameterList: () => {
        let params = [
            "systemParam.media",
            "systemParam.color",
            "systemParam.size"
        ];

        return params;
    },

    getSystemParameterListForMapping: () => {
        let params = [...Utils.getSystemParameterList()];
        params.push("systemParam.quantity");
        return params;
    },

    getSystemParameterListForHotFolder: () => {
        let params = [...Utils.getSystemParameterListForMapping()];
        params.push("systemParam.jobId");
        return params;
    },

    getImageBoxBounds: (jig) => {
        let minX = jig.width;
        let minY = jig.height;
        let maxX = 0;
        let maxY = 0;

        jig.slots.forEach((slot) => {
            const imageBox = slot.imageBox;
            minX = Math.min(minX, slot.x + imageBox.x);
            minY = Math.min(minY, slot.y + imageBox.y);
            maxX = Math.max(maxX, slot.x + imageBox.x + imageBox.width);
            maxY = Math.max(maxY, slot.y + imageBox.y + imageBox.height);
        })

        return {
            minX: minX,
            minY: minY,
            maxX: maxX,
            maxY: maxY,
        }
    },

    isAdmin: (user) => {
      if (!user || !user.role) {
        return false;
      }
  
      if (user.role.toLowerCase() === "admin") {
        return true;
      }
  
      return false;
    },

    isSuperAdmin: (user) => {
        if (!user || !user.role) {
          return false;
        }
    
        if (user.role.toLowerCase() === "superadmin") {
          return true;
        }
    
        return false;
    },

    isB2bRemoteUser: (user) => {
        if (!user || !user.type || !user.location) {
            return false;
        }

        if (user.type === "b2b" && user.location === "remote") {
            return true;
        }

        return false;
    },

    isB2cRemoteUser: (user) => {
        if (!user || !user.type || !user.location) {
            return false;
        }

        if (user.type === "b2c" && user.location === "remote") {
            return true;
        }

        return false;
    },

    isB2bRemoteOrB2cRemoteUser: (user) => {
        if (!user || !user.type || !user.location) {
            return false;
        }

        if ((user.type === "b2b" || user.type === "b2c") &&
        user.location === "remote") {
            return true;
        }

        return false;
    },

    isAdminOrB2bRemoteOrB2cRemoteUser: (user) => {
        return Utils.isAdmin(user) || Utils.isB2bRemoteOrB2cRemoteUser(user);
    },

    isSuperAdminOrB2bRemoteUser: (user) => {
        return Utils.isSuperAdmin(user) || Utils.isB2bRemoteUser(user);
    },

    isSuperAdminOrB2bRemoteOrB2cRemoteUser: (user) => {
        return Utils.isSuperAdmin(user) || Utils.isB2bRemoteOrB2cRemoteUser(user);
    },

    isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser: (user) => {
        return Utils.isAdmin(user) || Utils.isSuperAdmin(user) || Utils.isB2bRemoteOrB2cRemoteUser(user);
    },

    getDefaultAdminPermissions: () => {
        const adminPermissions = [
            Consts.PERMISSIONS_JIG, Consts.PERMISSIONS_PRINT_LAYOUT, Consts.PERMISSIONS_AUTOMATION_RULES,
            Consts.PERMISSIONS_USER_MANAGEMENT, Consts.PERMISSIONS_MASTERS, Consts.PERMISSIONS_INPUT_SETTINGS,
            Consts.PERMISSIONS_OUTPUT_SETTINGS, Consts.PERMISSIONS_CONFIGURATION,
            Consts.PERMISSIONS_DELETE_ORDERS, Consts.PERMISSIONS_DOWNLOADS, 
            Consts.PERMISSIONS_CHANGE_ORDER_JIG, Consts.PERMISSIONS_CHANGE_ORDER_ASSIGNEE,
            Consts.PERMISSIONS_OUTPUT_PRESET
        ].map(e => Utils.getPermissionEditName(e));
        return adminPermissions;
    },

    getDefaultPowerUserPermissions: () => {
        const powerPermissions = [
            Consts.PERMISSIONS_JIG, Consts.PERMISSIONS_PRINT_LAYOUT, Consts.PERMISSIONS_AUTOMATION_RULES,
            Consts.PERMISSIONS_MASTERS, Consts.PERMISSIONS_INPUT_SETTINGS, Consts.PERMISSIONS_OUTPUT_SETTINGS,
            Consts.PERMISSIONS_CONFIGURATION, Consts.PERMISSIONS_DOWNLOADS, Consts.PERMISSIONS_CHANGE_ORDER_JIG,
            Consts.PERMISSIONS_PRINT_ORDER, Consts.PERMISSIONS_OUTPUT_PRESET
        ].map(e => Utils.getPermissionEditName(e));
        return powerPermissions;
    },

    getDefaultOperatorPermissions: () => {
        const operatorPermissions = [
            Consts.PERMISSIONS_OUTPUT_SETTINGS, Consts.PERMISSIONS_CONFIGURATION, Consts.PERMISSIONS_DOWNLOADS, Consts.PERMISSIONS_CHANGE_ORDER_JIG,
            Consts.PERMISSIONS_PRINT_ORDER, Consts.PERMISSIONS_OUTPUT_PRESET
        ].map(e => Utils.getPermissionEditName(e)).
        concat([
            Utils.getPermissionViewName(Consts.PERMISSIONS_JIG),
            Utils.getPermissionViewName(Consts.PERMISSIONS_PRINT_LAYOUT)
        ]);
        return operatorPermissions;      
    },

    getDefaultPermissions: (user) => {
        if (!user || !user.role) {
            return [];
        }
        switch(user.role.toLowerCase()) {
            case 'superadmin':
                return Utils.getDefaultAdminPermissions();
            case 'admin':
                return Utils.getDefaultAdminPermissions();
            case 'poweruser':
                return Utils.getDefaultPowerUserPermissions();
            case 'operator':
                return Utils.getDefaultOperatorPermissions();
            default:
                return [];
        }
    },

    getArray: (item) => {
        
        let itemsArr = [];

        if (Array.isArray(item)) {
            itemsArr = [...item];
        } else if (typeof item === 'string' || item instanceof String) {
            itemsArr = item.split(',');
        }

        return itemsArr;
    },

    getPermissionEditName: (name) => {
        const editName = `${name}_edit`;
        return editName;
    },
    
    getPermissionViewName: (name) => {
        const viewName = `${name}_view`;
        return viewName;
    },

    isPermissionEditOnly: (name) => {
        const permissionEditOnlyList = [
            Consts.PERMISSIONS_DELETE_ORDERS,
            Consts.PERMISSIONS_CHANGE_ORDER_JIG,
            Consts.PERMISSIONS_DOWNLOADS,
            Consts.PERMISSIONS_CHANGE_ORDER_ASSIGNEE,
            Consts.PERMISSIONS_PRINT_ORDER,
            Consts.PERMISSIONS_OUTPUT_PRESET
        ]

        const result = permissionEditOnlyList.includes(name);
        return result;
    },
    
    getMasterDataIntersection: (action, jigsData, jigIds) => {
        const jigIdsSet = new Set();

        jigIds.forEach((e) => {
            const currJigData = jigsData.get(e);
            if (currJigData?.[action] && currJigData?.[action]?.length >= 1) {
                jigIdsSet.add(e);
            }
        });

        let intersection = [];
        let index = 0;

        jigIdsSet.forEach((item) => {
            const currJigDataAction = jigsData.get(item)[action];
            if (index === 0) {
                intersection = [...currJigDataAction];
            } else {
                intersection = currJigDataAction.filter(e => intersection.includes(e));
            }
            index++;
        });

        return intersection;
    },
    toPascal: s => {
        return s.replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
          new RegExp(/\s+(.)(\w*)/, 'g'),
          ($1, $2, $3) => `${$2.toUpperCase() + $3}`
        )
        .replace(new RegExp(/\w/), s => s.toUpperCase());
    },
    getClasses: (...cls) => {
        return cls.filter(i => i).join(' ');
    }
};

export default Utils;