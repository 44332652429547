import React from "react";
import IconButton from "../../../components/Buttons/IconButton";
import editOutlineImage from "../../../assets/edit-outline.svg";
import trashOutlineImage from "../../../assets/trash-outline.svg";
import warningOutlineImage from "../../../assets/warning-outline.svg";
import Utils from "../../../Utils";
import i18n from "../../../../i18n/i18n";
import OutputPreset from "./OutputPreset";
import nextId from 'react-id-generator';
import Consts from "../../../Consts";

const OrderUtils = {
    getDefaultColumnsOrdering: (isActive, permissions) => {
        // Return our default columns ordering.
        let defaultOrdering = [
            {
                field: 'orderId',
                visible: true,
                width: "6rem"
            },
            {
                field: 'dealerOrderId',
                visible: false,
                width: "6rem",
            },
            {
                field: 'jobId',
                visible: false,
                width: "6rem"
            },
            {
                field: "mediaName",
                visible: true,
                width: "8rem"
            },
            {
                field: 'mediaSize',
                visible: false,
                width: "6rem"
            },
            {
                field: 'mediaColor',
                visible: false,
                width: "6rem"
            },
            {
                field: "sku",
                visible: true,
                width: "8rem"
            },
            {
                field: 'copies',
                visible: true,
                width: "4rem"
            },
            {
                field: 'amountPrinted',
                visible: true,
                width: "4rem"
            },
            {
                field: 'assigned',
                visible: false,
                width: "4rem"
            },
            {
                field: 'jobStatus',
                visible: true,
                width: "4rem"
            },
            {
                field: 'jig',
                visible: false,
                width: "6rem"
            },
            {
                field: 'location',
                visible: false,
                width: "6rem"
            },
            {
                field: 'qualityLevel',
                visible: false,
                width: "6rem"
            },
            {
                field: 'printerType',
                visible: false,
                width: "6rem"
            },
            {
                field: 'mediaType',
                visible: false,
                width: "6rem"
            },
            {
                field: 'colorConfig',
                visible: false,
                width: "6rem"
            },
            {
                field: 'date',
                visible: false,
                width: "8rem"
            },
        ];
        // actions
        if (isActive && Utils.canDeleteOrders(permissions)) {
            defaultOrdering.push({
                field: 'edit',
                visible: true,
                width: "6rem"
            });
        } else if (isActive || Utils.canDeleteOrders(permissions)) {
            defaultOrdering.push({
                field: 'edit',
                visible: true,
                width: "4rem"
            });
        }
        return defaultOrdering;
    },

    getColumnsOrdering: (isActive, permissions) => {
        const defaultColumnsOrdering = OrderUtils.getDefaultColumnsOrdering(isActive, permissions);
        const storageKey = isActive ? Consts.STORAGE_KEY_ACTIVE_COLUMNS : Consts.STORAGE_KEY_HISTORY_COLUMNS;

        let columnsOrdering = localStorage.getItem(storageKey);
        if (columnsOrdering) {
            columnsOrdering = JSON.parse(columnsOrdering);
            if (columnsOrdering.length === defaultColumnsOrdering.length) {
                return columnsOrdering;
            }
        }
        return defaultColumnsOrdering;
    },

    getJobError: (jobData) => {
        // Only jig not assigned error for now.
        // Add more as needed.
        if (!jobData.jig) {
            return Consts.JOB_ERROR_NO_JIG;
        }
        return Consts.JOB_ERROR_NONE;
    },

    getJobErrorText: (errorCode) => {
        let errorText;
        switch (errorCode) {
            case Consts.JOB_ERROR_NO_JIG:
                errorText = i18n.t("orders.warning.noJig");
                break;
            default:
                errorText = "";
        }
        return errorText;
    },

    getColumns: (permissions, isActive, deleteOrderHandler, editOrderHandler) => {

        const columnsOrdering = OrderUtils.getColumnsOrdering(isActive, permissions);
        return columnsOrdering.map((column, index) => {
            if (column.field === "edit") {
                return {
                    id: index,
                    field: column.field,
                    width: column.width || "10rem",
                    label: Utils.getStringFromKey("orders." + column.field),
                    sortable: false,
                    visible: true,
                    cellRenderer: ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
                        return (<>
                            {isActive &&
                                <IconButton onClick={editOrderHandler} iconImage={editOutlineImage} tooltip={i18n.t('tooltip.edit')} />
                            }
                            {Utils.canDeleteOrders(permissions) &&
                                <IconButton onClick={deleteOrderHandler} iconImage={trashOutlineImage} tooltip={i18n.t('tooltip.delete')} />
                            }
                        </>
                        )
                    }
                }
            }
            else {
                const obj = {
                    id: index,
                    field: column.field,
                    width: column.width || "10rem",
                    sortable: false,
                    label: Utils.getStringFromKey("orders." + column.field),
                    visible: column.visible,
                }

                // Special handling for orderId column
                // Don't show the order id, if it matches the previous row.
                // Show warning icon if order has some issue.
                if (column.field === "orderId") {
                    obj.cellRenderer = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
                        value = data.orderId;
                        if (rowIndex > 1) {
                            let prevRow = tableManager.rowsApi.rows[rowIndex - 2];

                            if (value === prevRow.orderId) {
                                value = "";
                            }
                        }
                        const errorCode = OrderUtils.getJobError(data);
                        if (errorCode !== Consts.JOB_ERROR_NONE) {
                            return (<>
                                <div style={{
                                    backgroundImage: `url(${warningOutlineImage})`,
                                    position: "absolute",
                                    left: "0.2rem",
                                    top: "0.8rem",
                                    width: "1rem",
                                    height: "1rem",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    marginRight: "0.25rem"
                                }} title={OrderUtils.getJobErrorText(errorCode)} />
                                <p title={value}>{value}</p>
                            </>);
                        }
                        else {
                            return (
                                <p title={value}>{value}</p>
                            );
                        }
                    }
                }
                // Special handling for dealerOrderId column
                // Don't show the dealer order id, if it matches the previous row.
                else if (column.field === "dealerOrderId") {
                    obj.cellRenderer = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
                        value = data.dealerOrderId;
                        if (rowIndex > 1) {
                            let prevRow = tableManager.rowsApi.rows[rowIndex - 2];
                            // Don't show the order id, if it matches the previous row.
                            if (value === prevRow.dealerOrderId) {
                                value = "";
                            }
                        }
                        return (
                            <p title={value}>{value}</p>
                        );
                    }
                }
                // Show translated strings for job status.
                else if (column.field === "jobStatus") {
                    obj.cellRenderer = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
                        const translatedValue = i18n.t("orders." + data.jobStatus.toLowerCase());
                        return (
                            <p title={translatedValue}>{translatedValue}</p>
                        );
                    }
                }
                return obj;
            }
        });
    },

    checkSingleHost: (channels) => {
        if (channels.length) {
            let singleHost = true;
            let hostName = channels[0].hostName;
            singleHost = channels.every((channel) => channel.hostName === hostName);
            return singleHost;
        }
    },

    getChannelName: (channel, isSingleHost) => {
        let channelName = "";
        if (!channel) {
            return channelName;
        }
        if (channel.type && channel.name) {
            return `MJG ${channel.name}`;
        }
        if (channel.ripName === Consts.RIP_VERSAWORKS) {
            if (isSingleHost) {
                channelName = `VW ${channel.nickName} ${channel.queueLabel}`;
            }
            else {
                channelName = `VW ${channel.nickName} ${channel.queueLabel} (${channel.hostName})`;
            }
        }
        else if (channel.ripName === Consts.RIP_ERGOSOFT) {
            if (isSingleHost) {
                channelName = `ES ${channel.hotFolderPath}`;
            }
            else {
                channelName = `ES ${channel.hotFolderPath} (${channel.hostName})`;
            }
        }
        else if (channel.ripName === Consts.RIP_HOTFOLDER) {
            if (isSingleHost) {
                channelName = `HF ${channel.hotFolderName || channel.hotFolderPath}`;
            }
            else {
                channelName = `HF ${channel.hotFolderName || channel.hotFolderPath} (${channel.hostName})`;
            }
        } else if(channel.printer) {
            channelName = `MJG ${channel.name}`;
        }
        return channelName;
    },

    getOutputChannels: (channels) => {
        if (channels.length) {
            let isSingleHost = OrderUtils.checkSingleHost(channels);
            let options = channels.map((channel) => {
                let channelName = OrderUtils.getChannelName(channel, isSingleHost);
                return <option key={channel.id} value={channel.id}>{channelName}</option>
            });
            return options;
        }
        return [];
    },

    getMultiJobs: (multiJobs) => {
        if (multiJobs.length) {
            let options = multiJobs.map((job) => {
                let jobName = `MJG Duplicate ${job.name}`;
                return <option key={job.name} value={job.name}>{jobName}</option>
            });
            return options;
        }
        return [];
    },

    getOutputChannelsMap: (channels) => {
        const outputChannel = new Map();
        if (channels.length) {
            let isSingleHost = OrderUtils.checkSingleHost(channels);
            channels.forEach((channel) => {
                let channelName = OrderUtils.getChannelName(channel, isSingleHost);
                outputChannel.set(channel.id, channelName);
            });
        }
        return outputChannel;
    },

    getIntialTableData: (allOutputPresets, totalTables) => {
        const tableDataIntitalState = allOutputPresets.map((outputPreset) => {
            if (outputPreset.tableData) {
                return outputPreset.tableData;
            } else if (outputPreset.tableId && totalTables.get(outputPreset.tableId)) {
                return totalTables.get(outputPreset.tableId);
            }
            return undefined;
        });
        return tableDataIntitalState;
    },

    isPrintEnabled: (outputPreset, outputPresetIndex) => {
        if (outputPresetIndex >= 0 && outputPreset && outputPreset.tableData && outputPreset.tableData.isOrderAssigned) {
            return true;
        }
        return false;
    }

}

export default OrderUtils;