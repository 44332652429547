import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { updateConnectors } from "./InputSettingsActions";
import SliderButton from "../../components/Buttons/SliderButton";
import Modal from "../../components/Modal/Modal";
import PersBiz from "./PersBiz";
import HotFolder from "./HotFolder";
import CustomParams from "../CustomParams/CustomParams";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Consts from "../../Consts";
import Utils from "../../Utils";
import classes from "./InputSettings.module.css";

function getPersbizParamaterMapping(inputConnectors) {
    const persBizInput = inputConnectors.find((connector) => connector.type === Consts.INPUT_PERSBIZ);
    return persBizInput.mapping;
}

function getHotFolderSettings(inputConnectors) {
    // since inputConnectors has 1 persbiz object and others are hot folder objects
    return inputConnectors.filter((connector) => connector.type !== Consts.INPUT_PERSBIZ);
}

function InputSettings(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const inputSettings = useSelector((state) => state.inputSettings.inputSettings, shallowEqual) || {};
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");

    const [mapping, setMapping] = useState(getPersbizParamaterMapping(inputSettings.connectors) || []);
    const [hotFolder, setHotFolder] = useState(getHotFolderSettings(inputSettings.connectors) || []);
    const [learningMode, setLearningMode] = useState(inputSettings.learningMode || false);    
    const customParams = inputSettings.customParams || [];
    const mappingSuggestions = inputSettings.mappingSuggestions || [];

    const inputDisplayNames = {};
    inputDisplayNames[Consts.INPUT_PERSBIZ] = t('inputConnectors.persBiz');
    inputDisplayNames[Consts.INPUT_HOTFOLDER] = t('inputConnectors.hotFolder');

    const inputConnectorsArray = [Consts.INPUT_PERSBIZ];

    for (let ind = 1; ind <= Consts.HOT_FOLDER_COUNT; ind++) {
        inputConnectorsArray.push(`${Consts.INPUT_HOTFOLDER} ${ind}`);
    }

    const [activeConnectorIndex, setActiveConnectorIndex] = useState(0);
    const [showPersBiz, setShowPersBiz] = useState(true);
    const [showHotFolder, setShowHotFolder] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showCustomParams, setShowCustomParams] = useState(false);

    useEffect(() => {
        setMapping(getPersbizParamaterMapping(inputSettings.connectors));
        setHotFolder(getHotFolderSettings(inputSettings.connectors));
        setLearningMode(inputSettings.learningMode || false);
    }, [inputSettings]);

    function setActiveConnector(item, index) {

        let array = item.split(' ');
        let title = array[0];

        setActiveConnectorIndex(index);
        if (title === Consts.INPUT_PERSBIZ) {
            setShowHotFolder(false);
            setShowPersBiz(true);
        } else if (title === Consts.INPUT_HOTFOLDER) {
            setShowPersBiz(false);
            setShowHotFolder(true);
        }
    }

    function saveHandler() {
        const data = {
            connectors: [
                {
                    type: Consts.INPUT_PERSBIZ,
                    mapping: mapping,
                },
            ],
            learningMode
        }

        for (let ind = 1; ind <= Consts.HOT_FOLDER_COUNT; ind++) {
            data.connectors.push({
                type: `${Consts.INPUT_HOTFOLDER}${ind}`,
                order: hotFolder[ind - 1].order,
                separator: hotFolder[ind - 1].separator,
                fixValues: hotFolder[ind - 1].fixValues
            })
        }

        updateConnectors(dispatch, data).then((response) => {
            if (response && response.data && response.data.success) {
                props.onClose();
            }
            else {
                setShowError(true);
            }
        });
    }

    function closeErrorHandler() {
        setShowError(false)
    }

    function closeCustomParamsHandler() {
        setShowCustomParams(false);
    }

    function changeHotFolderHandler(data, index) {
        setHotFolder((prevState) => {
            const newState = [...prevState];
            newState[index] = {type: `${Consts.INPUT_HOTFOLDER}${index + 1}`, ...data};
            return newState;
        })
    }

    function isSaveDisabled() {
        if (Utils.canEditInputSettings(permissions)) {
            return false;
        }
        return true;
    }

    return (<Modal title={t('inputSettings.heading')} onClose={props.onClose}>
        {showCustomParams && <CustomParams onClose={closeCustomParamsHandler} onSave={closeCustomParamsHandler} />}
        {showError && Utils.showErrorDialog(t('inputSettings.error.updateFailed'), closeErrorHandler)}
        <div className={classes.main}>
            <div className={classes.modalContainer}>
                <div className={classes.leftContainer}>
                    <div className={classes.container}>
                        <div className={classes.containerHeader}>
                            <h3>{t('inputConnectors.heading')}</h3>
                        </div>
                        <div className={classes.containerBody}>
                            {inputConnectorsArray.map((item, index) => {
                                let array = item.split(' ');
                                let title = inputDisplayNames[array[0]];
                                if (index >= 1) {
                                    title = `${title} ${index}`
                                }
                                return <span key={item}
                                    title={title}
                                    style={{ backgroundColor: index === activeConnectorIndex ? Consts.SELECTION_COLOR : 'white' }}
                                    onClick={() => {
                                        setActiveConnector(item, index);
                                    }}>
                                    {
                                        index == 1 ? 
                                        <>
                                            <span>{title}</span>
                                            <span className={classes.help} title={t('inputConnectors.hotFolderInfo')}>?</span>
                                        </> : 
                                        title
                                    }
                                </span>
                            }).filter(val => val)
                            }
                        </div>
                    </div>
                    <div className={classes.customAddBtn}>
                        <PrimaryButton onClick={() => setShowCustomParams(true)} label={t('inputSettings.customParams.heading')} />
                        {!showHotFolder &&
                            <div className={classes.sliderBtn}>
                                <p>{t("inputSettings.learningMode")}</p> 
                                <SliderButton 
                                    onChange={(value) => setLearningMode(value)} 
                                    checkedLabel={t("inputSettings.learningMode.on")} 
                                    uncheckedLabel={t("inputSettings.learningMode.off")}
                                    uncheckedLabelStyle={{padding: "0 1rem 0 0", minWidth: "2rem"}} 
                                    checked={learningMode} />
                            </div>
                        }
                    </div>
                </div>
                {showPersBiz &&
                    <PersBiz mapping={mapping}
                        onChangeMapping={(mapping) => setMapping(mapping)}
                        suggestions={mappingSuggestions.concat().sort()} />
                }
                {showHotFolder &&
                    <HotFolder hotFolder={activeConnectorIndex >= 1 && activeConnectorIndex <= Consts.HOT_FOLDER_COUNT
                                            ? hotFolder[activeConnectorIndex - 1]
                                            : hotFolder[0]
                                        }
                        hotFolderIndex={activeConnectorIndex - 1}
                        onChange={changeHotFolderHandler}
                        customParams={customParams} />
                }
            </div>
            <div className={classes.actions}>
                <SecondaryButton onClick={props.onClose} label={t('button.cancel')} />
                <PrimaryButton label={t('button.save')} onClick={saveHandler} disabled={isSaveDisabled()}/>
            </div>
        </div>
    </Modal>);
}

export default InputSettings;