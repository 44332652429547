import React, { useState } from 'react'
import Consts from '../../Consts';
import IconButton from '../../components/Buttons/IconButton';
import trashOutlineImage from "../../assets/trash-outline.svg";
import editOutlineImage from "../../assets/edit-outline.svg";
import classes from './OutputSettings.module.css';
import { useEffect } from 'react';
import InlineTextEditor from '../../components/InlineTextEditor/InlineTextEditor';

export default function OutputItem({title, selected, onClick, onDelete, onEdit}) {
    const [isEditing, setIsEditing] = useState(false);

    return <div className={classes.filterNode} 
        style={{ backgroundColor: selected ? Consts.SELECTION_COLOR : 'White' }}
        onClick={onClick}
    >
        <span title={title}>{title}</span>
        {
            isEditing && <InlineTextEditor
                onSave={e => {
                    onEdit && onEdit(e);
                    setIsEditing(false);
                }} onClose={() => setIsEditing(false)} 
                saveEnabled={true}
                style={{width: '100%', left: 'unset'}}
                value={title}
            />
        }
        <div className={classes.editButtons}>
            {
                onEdit && <IconButton onClick={(e) => {
                    e.stopPropagation();
                    setIsEditing(true);
                }} iconImage={editOutlineImage} />
            }
            {
                onDelete && <IconButton onClick={(e) => {
                    e.stopPropagation();
                    onDelete && onDelete();
                }} iconImage={trashOutlineImage} />
            }
        </div>
    </div>
}