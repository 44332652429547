import React from 'react';

const customStyles = {
    option: (provided) => ({
        ...provided,
        height: '2rem',
        display: 'flex',
        alignItems: 'center',
        
    }),
    control: (provided) => ({
        ...provided,
        maxHeight: '4rem',
        overflowY: 'auto',
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '10rem',
        overflowY: 'auto',
    }),
    singleValue: (provided) => ({
        ...provided,
    })
}

export default customStyles;