import React from 'react';

function OrderViewer({job}) {
    const imagePath = `${job.thumbnailPath}?customer=${window.getResourceName()}`;
    return (
        <>
            <img src={encodeURI(imagePath)} style={{maxHeight: '100%', maxWidth: '100%'}}/>
            {
                job.additionalParams && Boolean(job.additionalParams.length) &&
                <div style={{marginLeft: '1rem', background: '#fff', padding: 10}}>
                    <table>
                    {
                        job.additionalParams.map(p => 
                            <tr>
                                <td>{p.key}</td>
                                <td width={10}> </td>
                                <td>{p.value}</td>
                            </tr>
                        )
                    }
                    </table>
                </div>
            }
        </>
    );
}

export default OrderViewer;