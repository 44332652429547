import React, { useEffect, useRef, useState } from "react";
import IconButton from "../../components/Buttons/IconButton";
import InlineTextEditor from "../../components/InlineTextEditor/InlineTextEditor";
import trashOutlineImage from "../../assets/trash-outline.svg";
import editOutlineImage from "../../assets/edit-outline.svg";
import classes from "./MasterItem.module.css";

function MasterItem(props) {
    const item = props.item;
    const ref = useRef();
    const [isEditing, setIsEditing] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isEditing && ref.current && !ref.current.contains(e.target)) {
                setIsEditing(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isEditing]);

    function valueChangedHandler(newValue) {
        if (newValue === "" || (props.disallowedValues.includes((newValue.toLowerCase())) && newValue.toLowerCase() !== item.toLowerCase())) {
            setSaveEnabled(false);
        }
        else {
            setSaveEnabled(true);
        }
    }

    function saveHandler(value) {
        props.onSave(value, props.index);
        setIsEditing(false);
    }

    return (<tr key={item}>
        <td style={{ "textAlign": "left" }}>{props.index + 1}</td>
        <td style={{ "textAlign": "left" }} ref={ref}>
            {isEditing ? 
                <div className={classes.editText}>
                    <InlineTextEditor onSave={saveHandler} 
                        onClose={() => setIsEditing(false)} 
                        onChange={valueChangedHandler} 
                        value={props.item} 
                        saveEnabled={saveEnabled} />
                </div> 
                       : <div className={classes.text} title={item}>{item}</div>
            }
        </td>
        <td style={{ "textAlign": "center" }}>
            <IconButton onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
            }} iconImage={editOutlineImage} />
            <IconButton onClick={(e) => {
                e.stopPropagation();
                props.onDelete(props.index);
            }} iconImage={trashOutlineImage} />
        </td>
    </tr>
    );
}

export default MasterItem;
