import React, { useEffect, useState } from 'react';
import Switch from "react-switch";
import classes from './SliderButton.module.css';

function SliderButton(props) {
    const [checked, setChecked] = useState(props.checked || false);

    function handleChange(e) {
        setChecked(e);
        props.onChange && props.onChange(e);
    }

    useEffect(() => {
        setChecked(props.checked || false);
    }, [props.checked]);

    return (<div className={classes.main}>
        <div>
            <label style={props.uncheckedLabelStyle}>{props.uncheckedLabel}</label>
        </div>
        <Switch
            checked={checked}
            onChange={handleChange}
            offColor="#FFF"
            onColor="#FFF"
            offHandleColor="#0E5FBC"
            onHandleColor="#0E5FBC"
        />
        <div>
            <label>{props.checkedLabel}</label>
        </div>
    </div>)
}

export default SliderButton;
