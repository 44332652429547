import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./OutputPreset.module.css";
import OrderUtils from "../Orders/components/OrderUtils";
import Consts from "../../Consts";
import OutputItem from "../OutputSettings/OutputItem";
import SettingBox, { Select } from "./SettingBox";
import { useIdMap } from "../../custom-hooks/IdMap";
import Utils from "../../Utils";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import OutputSettings from "../OutputSettings/OutputSettings";
import { getAllOutputChannels } from "../Orders/OutputChannelActions";
import { useDispatch } from "react-redux";

const notAvailable = "NA";

function getMultiJobFileName(type, numberOfExistingFiles) {
    let name = `File ${numberOfExistingFiles + 1}`;
    if (type === Consts.MULTIJOB_DUPLICATE) {
        name = `${Consts.MULTIJOB_DUPLICATE_FILE} ${numberOfExistingFiles}`;
    } else if (type === Consts.MULTIJOB_COMBINED) {
        name = `${Consts.MULTIJOB_COMBINED_FILE} ${numberOfExistingFiles}`;
    }
    return name;
}

export function isHotFolderChannel(channel) {
    let ripName = channel?.ripName;
    return ripName === Consts.RIP_HOTFOLDER || ripName === Consts.RIP_ERGOSOFT;
}

export default function ChannelSelection({
    outputChannels,
    onInfoChange,
    outputPreset,
}) {
    const { t } = useTranslation();
    const channelTypes = [
        {
            name: t("orders.channelType.normal"),
            type: "normal",
        },
        {
            name: t("orders.channelType.multiJob"),
            type: "multiJob",
        },
    ];
    const [outputChannel, setChannel] = useState(outputPreset.outputChannel);
    const [files, setFiles] = useState(outputPreset.files || []);
    const [selectedFileIndex, setSelectedFileIndex] = useState();
    const [showOutputSettings, setShowOutputSettings] = useState(false);

    const dispatch = useDispatch();

    const printerSettingFiles = outputChannel?.printerSettingFiles || [];
    useEffect(() => {
        if (files?.length) {
            setSelectedFileIndex(0);
            setPresetName(files[0].printerSettingFile);
        }
    }, [outputChannel]);

    const [type, setType] = useState(outputPreset.type || channelTypes[0].type);
    const [channelMap] = useIdMap(outputChannels);
    const [presetName, setPresetName] = useState(
        outputPreset?.preset?.presetName || ""
    );
    
    useEffect(() => {
        if (outputChannel && channelMap) {
            setChannel(channelMap[outputChannel.id]);
        }
    }, [channelMap, outputChannel]);

    const selectPreset =
        printerSettingFiles.find((i) => i.presetName === presetName) || {};

    useEffect(() => {
        onInfoChange && onInfoChange({ outputChannel, type, files });
    }, [outputChannel, type, files]);

    const isSingleHost = OrderUtils.checkSingleHost(outputChannels);
    return (
        <div className={classes.channelSelection}>
            {showOutputSettings && <OutputSettings onClose={() => setShowOutputSettings(false)} 
                selectedUserId={outputPreset?.userId}
                selectedOutputChannel={outputChannel}
            />}
            <div className={`${classes.box} ${classes.commonLayout}`}>
                <div style={{ paddingRight: "1rem" }}>
                    <Select
                        label={t("orders.select.outputChannel")}
                        options={outputChannels.map((u) => ({
                            label: OrderUtils.getChannelName(u, isSingleHost),
                            value: u.id,
                        }))}
                        value={outputChannel?.id || ""}
                        onChange={(id) => {
                            let channel = channelMap[id],
                                settings = channel?.printerSettingFiles || [];
                            setChannel(channel);
                            setFiles([
                                {
                                    name: getMultiJobFileName(outputChannel?.type, 0),
                                    printerSettingFile: settings[0]?.presetName || notAvailable,
                                },
                            ]);
                        }}
                    />
                    <PrimaryButton label={t("edit.outputChannels")}
                        onClick={e => {
                            setShowOutputSettings(true);
                        }}
                        disabled={!outputChannel?.id} 
                    />
                </div>
                {type === "multiJob" && !isHotFolderChannel(outputChannel) && (
                    <div className={`${classes.rightBorder} ${classes.jobContainer}`}>
                        <SettingBox
                            containerStyle={{ height: "70%" }}
                            title={t("outputChannels.job")}
                            items={files || []}
                            onUp={(item, idx) => {
                                const nfiles = files.slice();
                                nfiles[idx] = nfiles[idx - 1];
                                nfiles[idx - 1] = item;
                                setFiles(nfiles);
                            }}
                            onDown={(item, idx) => {
                                const nfiles = files.slice();
                                nfiles[idx] = nfiles[idx + 1];
                                nfiles[idx + 1] = item;
                                setFiles(nfiles);
                            }}
                            onAddButton={(e) => {
                                const nfiles = files.slice();
                                nfiles.push({
                                    name: getMultiJobFileName(outputChannel.type, files.length),
                                    printerSettingFile: notAvailable,
                                });
                                setFiles(nfiles);
                            }}
                            disableAddButton={!outputChannel}
                            selectedItem={files?.[selectedFileIndex]}
                            ItemComponent={({ item, index, onSelectionChange, selected }) => (
                                <OutputItem
                                    onDelete={(e) => setFiles(files.filter((f) => f !== item))}
                                    onEdit={(name) => {
                                        const nfiles = files.slice();
                                        nfiles[index] = { ...item, name };
                                        setFiles(nfiles);
                                    }}
                                    title={item.name}
                                    selected={selected}
                                    onClick={(e) => {
                                        onSelectionChange(e);
                                        setSelectedFileIndex(index);
                                        setPresetName(item.printerSettingFile);
                                    }}
                                />
                            )}
                        />
                    </div>
                )}
            </div>
            {!isHotFolderChannel(outputChannel) && (
                <div className={`${classes.box}`}>
                    <div style={{ paddingRight: "1rem" }}>
                        <SettingBox
                            title={t("orders.select.jobType")}
                            items={channelTypes}
                            itemComparator={(a, b) => a?.type === b?.type}
                            containerStyle={{ height: "unset" }}
                            selectedItem={channelTypes.find((i) => i.type === type)}
                            ItemComponent={({ item, onSelectionChange, selected }) => (
                                <OutputItem
                                    title={item.name}
                                    selected={selected}
                                    onClick={(e) => {
                                        setType(item.type);
                                        onSelectionChange();
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div
                        className={`${classes.rightBorder} ${classes.printSettingContainer}`}
                    >
                        <SettingBox
                            title={t("orders.select.printerSetting")}
                            onReload={e => {
                                getAllOutputChannels(dispatch);
                            }}
                            containerStyle={{ height: "unset" }}
                        >
                            <Select
                                options={[
                                    {label: 'Use queue settings', value: ''},
                                    ...printerSettingFiles.map((i) => ({
                                        label: i.presetName,
                                        value: i.presetName,
                                    }))
                                ]}
                                value={presetName}
                                onChange={(val) => {
                                    setPresetName(val);
                                    setFiles((f) => {
                                        let files = [...f],
                                            file = files[selectedFileIndex];
                                        files[selectedFileIndex] = {
                                            ...file,
                                            printerSettingFile: val,
                                        };
                                        return files;
                                    });
                                }}
                                defaultOption={false}
                            />
                        </SettingBox>
                    </div>

                    <div
                        className={`${classes.rightBorder} ${classes.queuePresetContainer}`}
                    >
                        <SettingBox
                            title={t("orders.queuePreset")}
                            items={Object.keys(selectPreset).map((k) => ({
                                label: Utils.toPascal(k),
                                value: selectPreset[k],
                            }))}
                            containerStyle={{ height: "unset" }}
                            style={{ padding: 10 }}
                            ItemsWrapper={({children}) => <table><tbody>{children}</tbody></table>}
                            ItemComponent={({ item }) => {
                                return (
                                    <tr>
                                        <td>{item.label}</td>
                                        <td width={10}></td>
                                        <td>{item.value}</td>
                                    </tr>
                                );
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}