import React, { useEffect, useState } from "react";
import classes from './Loading.module.css';

function Loading({target, text}) {
    const [show, setShow] = useState(0);
    useEffect(() => {
        if (target) {
            target.loading = function(loading) {
                setShow(state => {
                    state = state + (loading ? 1 : -1);
                    return state > 0 ? state : 0;
                });
            };
            return () => delete target.loading;
        }
    }, [target]);
    return (
        Boolean(show) && <>
            <div className={classes.backdrop}> </div>
            <div className={classes.main} >
                <span>{text} </span>
                <div className={classes.loader}></div>
            </div>
        </>
    );
}

export default Loading;