import { configureStore } from '@reduxjs/toolkit';
import jigsReducer from "./App/modules/Jigs/JigsSlice";
import tablesReducer from "./App/modules/Tables/TablesSlice";
import ordersReducer from "./App/modules/Orders/OrdersSlice";
import filtersReducer from "./App/modules/Filters/FiltersSlice";
import usersReducer from "./App/modules/Users/UsersSlice";
import outputChannelsReducer from './App/modules/Orders/OutputChannelSlice';
import outputPresetsReducer from './App/modules/Orders/OutputPresetSlice';
import masterReducer from './App/modules/Master/MasterSlice';
import configReducer from './App/modules/Config/ConfigSlice';
import inputSettingsReducer from './App/modules/InputSettings/InputSettingsSlice';
import outputSettingsReducer from './App/modules/OutputSettings/OutputSettingsSlice';
import customersReducer from "./App/modules/Customers/CustomersSlice";

// Configure our store combining all the state reducers.
export const store = configureStore({
  reducer: {
    jigs: jigsReducer,
    tables: tablesReducer,
    orders: ordersReducer,
    filters: filtersReducer,
    users: usersReducer,
    outputChannels: outputChannelsReducer,
    outputPresets: outputPresetsReducer,
    master: masterReducer,
    config: configReducer,
    inputSettings: inputSettingsReducer,
    outputSettings: outputSettingsReducer,
    customers: customersReducer
  }
});
