import React from "react";
import classes from "./Instruction.module.css";

function Instruction(props) {
    
    return (<>
        {props.link 
            ? <a className={`${classes.main} ${classes.link}`} href={props.link} style={props.style}>
                {props.value}
            </a>
            : <div className={classes.main} style={props.style}> 
                {props.value}
            </div>
        }
    </>);
}

export default Instruction;