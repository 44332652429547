import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Consts from "../../Consts";
import { useContainerDimensions } from "../../custom-hooks/ContainerDimensions";
import Utils from "../../Utils";
import classes from './TablePreview.module.css';

let getSlotsMarkup = (slot, index, width, height, scaling, jigKey, showImageBox) => {
    const strokeWeight = `${1 / scaling}px`;
    const imageBoxPath = Utils.getSlotShapeMarkup(slot.imageBox, Consts.IMAGE_BOX_FILL_COLOR, Consts.IMAGE_BOX_STROKE_COLOR, strokeWeight);
    return (
        <g key={jigKey + "_slot" + index}>
            {Utils.getSlotShapeMarkup(slot, Consts.SLOT_FILL_COLOR, Consts.SLOT_STROKE_COLOR, strokeWeight)}
            {showImageBox &&
                <g transform={`translate(${slot.x} ${slot.y})`}>
                    {imageBoxPath}
                </g>
            }
        </g>
    );
}

function TablePreview(props) {
    const containerRef = useRef();
    const { width, height } = useContainerDimensions(containerRef);
    const allJigs = useSelector((state) => state.jigs.allJigs);

    const table = props.previewTable;
    const validTable = (table && table.width && table.height); // defined and not zero

    // Compute the scaling from table to preview coordinates.
    let scaling = 1.0;
    if (validTable) {
        const rx = width / table.width;
        const ry = height / table.height;
        scaling = rx < ry ? rx : ry; // Fit the table in available space
        if (scaling === 0) {
            // On first preview render, we get width and height of preview container as 0.
            // This causes scaling to become 0, and result in Infinity results dividing by scaling.
            // We set the scaling back to 1.0 here.
            scaling = 1.0;
        }
    }

    let jigsData = new Map();
    allJigs.jigs.forEach((jig) => {
        jigsData.set(jig.id, {
            name: jig.name,
            width: jig.width,
            height: jig.height,
            slots: jig.slots
        });
    });

    let overlap = [];
    const jigs = table.jigs || [];

    jigs.forEach((jig) => {
        const jigData = jigsData.get(jig.id);
        if (jigData) {
            let obj = {
                x: jig.x,
                y: jig.y,
                width: jigData.width,
                height: jigData.height
            }
            overlap.push(obj);
        }
    });

    let overlapIndexes;
    if (props.showValidation) {
        overlapIndexes = Utils.overlapIndexes(overlap, table.width, table.height);
    }

    function getJigName(jig) {
        return `${jigsData.get(jig.id).name}`;
    }

    const fontSize = `${0.75 / scaling}rem`;
    const strokeWeight = `${1 / scaling}px`;
    const dashLength = `${4 / scaling}px`;

    let getTableBoundsMarkup = () => {
        return <rect width={table.width} height={table.height} stroke={Consts.TABLE_STROKE_COLOR} strokeWidth={strokeWeight} fill={Consts.TABLE_FILL_COLOR} />;
    };

    let getTablePrintableBoundsMarkup = () => {
        const printArea = table.printableArea;
        if (printArea && !isNaN(printArea.x) && !isNaN(printArea.y) && !isNaN(printArea.width) && !isNaN(printArea.height)) {
            return <rect x={printArea.x} y={printArea.y} width={printArea.width} height={printArea.height} stroke={Consts.TABLE_PRINTABLE_STROKE_COLOR} strokeWidth={strokeWeight} fill={Consts.TABLE_PRINTABLE_FILL_COLOR} strokeDasharray={dashLength} />;
        }
    };

    let getJigsMarkup = () => {
        return jigs.map((jig, index) => {
            const jigData = jigsData.get(jig.id);
            if (!jigData || isNaN(jig.x) || isNaN(jig.y)) {
                return null;
            }

            const jigKey = table.id + "_jigPreview" + index;

            let transformation = `translate(${jig.x}, ${jig.y})`;
            let slotsMarkup = jigData.slots.map((slot, i) => {
                return getSlotsMarkup(slot, i, jigData.width, jigData.height, scaling, jigKey, props.showImageBox);
            });

            return (
                <g key={jigKey} transform={transformation}>
                    <rect width={jigData.width} height={jigData.height} fill={Consts.JIG_FILL_COLOR} stroke={props.showValidation && overlapIndexes.has(index) ? Consts.STROKE_COLOR_WARN : Consts.JIG_STROKE_COLOR} strokeWidth={strokeWeight}></rect>
                    {slotsMarkup}
                    <g transform="scale(-1)">
                        <text x={-jigData.width / 2} y={-jigData.height / 2 + (10 / scaling)} alignmentBaseline="middle" textAnchor="middle" fontSize={fontSize} fontWeight="bold" fill={Consts.TEXT_COLOR}>{index + 1}</text>
                        <text x={-jigData.width / 2} y={-jigData.height / 2 - (10 / scaling)} alignmentBaseline="middle" textAnchor="middle" fontSize={fontSize} fill={Consts.TEXT_COLOR}>{getJigName(jig)}</text>
                    </g>
                </g>
            );
        });
    }

    const scaledTableWidth = scaling * table.width;
    const scaledTableHeight = scaling * table.height;

    return (<>
        <div className={classes.previewContainer} style={props.style} ref={containerRef}>
            {validTable && <svg width={scaledTableWidth + 10} height={scaledTableHeight + 10} >
                <g transform={`scale(${scaling})`}>
                    <g transform={`scale(-1) translate(${-table.width} ${-table.height})`}>
                        {getTableBoundsMarkup()}
                        {getJigsMarkup()}
                        {getTablePrintableBoundsMarkup()}
                        {props.showOrigin && Utils.getOriginMarkerMarkup(scaling)}
                    </g>
                </g>
            </svg>}
        </div>
    </>);
}

export default TablePreview;