import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./BackButton.module.css";
import backArrowIcon from "../../assets/back-arrow-icon.svg";
function BackButton(props) {

    const { t } = useTranslation();
    return (<div className={classes.main}>
        <button type="button" onClick={props.onBack}>
            <div className={classes.btnWrapper}>
                <img src={backArrowIcon} width="17px" height="17px" alt="Back Arrow Icon" />
                <p>{t('button.back')}</p>
            </div>
        </button>
    </div>);

}

export default BackButton;