import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { getAllPrinters, updateMyPrinters } from "./OutputSettingsActions";
import { getAllOutputChannels, getOutputChannels } from "../Orders/OutputChannelActions";
import Modal from "../../components/Modal/Modal";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import DeviceSettingCheckBox from "./DeviceSettingCheckBox";
import DeviceSettingOption from "./DeviceSettingOption";
import OutputSettingContainer from "./OutputSettingContainer";
import OutputSettingSubContainer from "./OutputSettingSubContainer";
import Instruction from "../../components/Instruction/Instruction";
import Utils from "../../Utils";
import Consts from "../../Consts";
import classes from "./OutputSettings.module.css";


function OutputSettings({onClose, selectedUserId, selectedOutputChannel}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const allOutputChannels = useSelector((state) => state.outputChannels.allOutputChannels, shallowEqual);
    const outputSettings = useSelector((state) => state.outputSettings.outputSettings, shallowEqual);
    const allPrinterTypes = useSelector((state) => state.master.allMasters.printerType, shallowEqual) || [];
    const allUsers = useSelector((state) => state.users.allUsers, shallowEqual) || {};
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");

    let initRipIndex = -1;
    let initPrinterIndex = -1;
    let initHotFolderIndex = -1;

    const [selectedUser, setSelectedUser] = useState({});
    const [myRips, setMyRips] = useState({});
    const [activeRipIndex, setActiveRipIndex] = useState(initRipIndex);
    const [activePrinterIndex, setActivePrinterIndex] = useState(initPrinterIndex);
    const [activeHotFolderIndex, setActiveHotFolderIndex] = useState(initHotFolderIndex);
    const [showError, setShowError] = useState(false);
    const [users, setUsers] = useState([]);

    const printerTypeProperty = "printerType";
    const outputRotationProperty = "outputRotation";
    const referenceZeroPointAsLeftProperty = "referenceZeroPointAsLeft";
    const autoCroppingProperty = "autoCropping";

    const outputChannelArray = [];
    const ripDisplayNames = {};
    ripDisplayNames[Consts.RIP_VERSAWORKS] = t('outputChannels.versaWorks');
    ripDisplayNames[Consts.RIP_HOTFOLDER] = t('outputChannels.localHotFolder');

    if (selectedUser && selectedUser.id && 
        myRips.hasOwnProperty(selectedUser.id)) {

        if (myRips[selectedUser.id].hasOwnProperty(Consts.RIP_VERSAWORKS)) {
            outputChannelArray.push(Consts.RIP_VERSAWORKS);
        }

        if (myRips[selectedUser.id].hasOwnProperty(Consts.RIP_HOTFOLDER)) {
            outputChannelArray.push(Consts.RIP_HOTFOLDER);
        }
    }

    useEffect(() => {
        if (allUsers) {
            const currentUser = allUsers.currentUser;
            setUsers(
                currentUser && !Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(currentUser) 
                ? [currentUser] 
                : allUsers.users.filter(user => !Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(user))
            );
        }
    }, [allUsers]);

    useEffect(() => {
        if (users && users.length) {
            let idx = users.findIndex(u => u.id === selectedUserId);
            if (idx < 0) {
                idx = 0;
            }
            setSelectedUser(users[idx]);
        }
    }, [users, selectedUserId]);

    useEffect(() => {
        if (selectedOutputChannel) {
            setActiveRipIndex(outputChannelArray.findIndex(rip => rip === selectedOutputChannel.ripName));
            let rip = selectedOutputChannel.ripName;
            let idx = myRips[selectedUser.id]?.[rip]?.findIndex(s => s.modelName === selectedOutputChannel.modelName);
            switch (rip) {
                case Consts.RIP_VERSAWORKS:
                    setActivePrinterIndex(idx);
                    break;
                case Consts.RIP_HOTFOLDER:
                    setActiveHotFolderIndex(idx);
                    break;
            }
        }
    }, [myRips, selectedUser, selectedOutputChannel])

    useEffect(() => {
        if (users && users.length > 0) {
            const ripsData = {};
            users && users.forEach((user) => {
                const outputChannels = allOutputChannels && allOutputChannels.length > 0 
                    ? allOutputChannels.filter((outputChannel) => outputChannel.userId === user.id)
                    : [];
                let printers = [];
                outputSettings && outputSettings.myPrinters && outputSettings.myPrinters.length > 0 &&
                    outputSettings.myPrinters.forEach((printersObj) => {
                        if (printersObj.userId === user.id) {
                            printers = [...printersObj.myPrinterData];
                        }
                    })
                ripsData[user.id] = Utils.getRips(outputChannels, printers);
            })
            setMyRips(ripsData);
        }
    }, [allOutputChannels, outputSettings, users])

    function getNewRips(rips, selectedRip, ripItemIndex, property, value) {
        const activeRipItem = rips[selectedUser.id][selectedRip][ripItemIndex];
        let newRipItem = { ...activeRipItem };
        newRipItem[property] = value;

        let newRips = {
            ...rips
        };
        newRips[selectedUser.id][selectedRip][ripItemIndex] = newRipItem;
        return newRips;
    }

    function setMyRipsHandler(property, value) {
        setMyRips((prevState) => {
            if (activePrinterIndex >= 0) {
                return getNewRips(prevState, Consts.RIP_VERSAWORKS, activePrinterIndex, property, value);
            } else if (activeHotFolderIndex >= 0) {
                return getNewRips(prevState, Consts.RIP_HOTFOLDER, activeHotFolderIndex, property, value);
            }
            return prevState;
        });
    }

    function getRipsPropertysValue(property) {
        let value;
        const initialValue = value;

        if (activePrinterIndex >= 0) {
            value = myRips[selectedUser.id][Consts.RIP_VERSAWORKS][activePrinterIndex][property];
        } else if (activeHotFolderIndex >= 0) {
            value = myRips[selectedUser.id][Consts.RIP_HOTFOLDER][activeHotFolderIndex][property];
        }

        if (value === initialValue) {
            // if value could not be found
            switch (property) {
                case printerTypeProperty :
                    value = Consts.DEFAULT_PRINTER_TYPE;
                    break;
                case outputRotationProperty : 
                    value = Consts.DEFAULT_OUTPUT_ROTATION;
                    break;
                case referenceZeroPointAsLeftProperty : 
                    value = Consts.DEFAULT_REFERENCE_ZERO_POINT_AS_LEFT;
                    break;
                case autoCroppingProperty :
                    value = Consts.DEFAULT_AUTO_CROPPING;
                    break;
            }
        }

        return value;
    }

    function getPrinterType() {
        const value = getRipsPropertysValue(printerTypeProperty);
        return value;
    }

    function getOutputRotation() {
        const value = getRipsPropertysValue(outputRotationProperty);
        return value;
    }

    function getReferenceZeroPointAsLeft() {
        const value = getRipsPropertysValue(referenceZeroPointAsLeftProperty);
        return value;
    }

    function getAutoCropping() {
        const value = getRipsPropertysValue(autoCroppingProperty);
        return value;
    }

    function saveHandler() {
        const printerData = [];
        users.forEach((user) => {
            let data = {
                userId: user.id,
                myPrinterData: [],
                myHotFolderData: []
            }
            if (myRips[user.id].hasOwnProperty(Consts.RIP_VERSAWORKS)) {
                data = {...data,
                    myPrinterData: myRips[user.id][Consts.RIP_VERSAWORKS]
                }
            }
            if (myRips[user.id].hasOwnProperty(Consts.RIP_HOTFOLDER)) {
                data = {...data,
                    myHotFolderData: myRips[user.id][Consts.RIP_HOTFOLDER]
                }
            }
            printerData.push(data);
        })

        if (printerData.length > 0) {
            let data = {
                data : [...printerData]
            }

            updateMyPrinters(dispatch, data).then((response) => {
                if (response && response.data && response.data.success) {
                    if (Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(allUsers.currentUser)) {
                        getAllOutputChannels(dispatch);
                    }
                    else {
                        getOutputChannels(dispatch);
                    }
                }
                else {
                    setShowError(true);
                }
            });

        }

        onClose && onClose();
    }

    function closeErrorHandler() {
        setShowError(false)
    }

    function isSaveDisabled() {
        if (Utils.canEditOutputSettings(permissions)) {
            return false;
        }
        return true;
    }

    function getPrinterName(printer) {
        if (allOutputChannels && allOutputChannels.length > 0) {
            for (let index = 0; index < allOutputChannels.length; index++) {
                const outputChannel = allOutputChannels[index];
                if (outputChannel.modelName === printer.modelName && 
                    outputChannel.nickName === printer.nickName) {
                        return `${outputChannel.modelName} ${outputChannel.nickName} (${outputChannel.hostName})`;
                }
            }
        }
        return `${printer.modelName} ${printer.nickName}`;
    }

    const smallContainerStyle = {"width": "calc(21% - 1.5rem)"};
    const largeContainerStyle = {"width": "calc(28% - 1.5rem)"};

    return (<Modal title={t('outputChannels.heading')} onClose={onClose}>
        {showError && Utils.showErrorDialog(t('outputChannels.error.updateFailed'), closeErrorHandler)}
        <div className={classes.main}>
            <div className={classes.modalContainer}>
                <OutputSettingContainer style={smallContainerStyle}>
                    <OutputSettingSubContainer
                        heading={t("users.heading")}>
                        {users.map((item) => {
                            let title = item.userId;
                            return <span key={item.id}
                                title={title}
                                style={{ backgroundColor: item.id === selectedUser.id ? Consts.SELECTION_COLOR : 'White' }}
                                onClick={() => {
                                    setSelectedUser(item); 
                                    setActiveRipIndex(initRipIndex); 
                                    setActivePrinterIndex(initPrinterIndex);
                                    setActiveHotFolderIndex(initHotFolderIndex);
                                    }}>
                                {title}
                            </span>
                        }).filter(val => val)
                        }
                    </OutputSettingSubContainer>
                </OutputSettingContainer>
                {selectedUser && selectedUser.id && 
                <OutputSettingContainer style={smallContainerStyle}>
                    <OutputSettingSubContainer 
                        headingChildren={<h3 className={classes.outputChannelHeading}>
                            <span>{t('outputChannels.channels')}</span>
                            <span className="fa fa-refresh" onClick={e => {
                                getAllOutputChannels(dispatch);
                                getAllPrinters(dispatch);
                            }}/>
                        </h3>}
                    >
                        {outputChannelArray.map((item, index) => {
                            let title = ripDisplayNames[item];
                            return <span key={item}
                                title={title}
                                style={{ backgroundColor: index === activeRipIndex ? Consts.SELECTION_COLOR : 'White' }}
                                onClick={() => {
                                    setActiveRipIndex(index);
                                    setActivePrinterIndex(initPrinterIndex);
                                    setActiveHotFolderIndex(initHotFolderIndex);
                                }}>
                                {title}
                            </span>
                        }).filter(val => val)
                        }
                    </OutputSettingSubContainer>
                </OutputSettingContainer>
                }
                {outputChannelArray.includes(Consts.RIP_VERSAWORKS) && activeRipIndex >= 0 && outputChannelArray[activeRipIndex] !== Consts.RIP_HOTFOLDER &&
                    selectedUser && selectedUser.id && myRips.hasOwnProperty(selectedUser.id) && 
                    myRips[selectedUser.id].hasOwnProperty(Consts.RIP_VERSAWORKS) &&
                    <OutputSettingContainer style={largeContainerStyle}>
                        <OutputSettingSubContainer 
                            heading={t('outputChannels.printerModel')}>
                             {myRips[selectedUser.id][Consts.RIP_VERSAWORKS].map((item, index) => {
                                    let title = getPrinterName(item);
                                    return <span key={`${item.modelName}_${item.nickName}`}
                                        title={title}
                                        style={{ backgroundColor: (index === activePrinterIndex) ? Consts.SELECTION_COLOR : 'White' }}
                                        onClick={() => {
                                            setActivePrinterIndex(index);
                                        }}>
                                        {title}
                                    </span>
                                }).filter(val => val)
                            }
                        </OutputSettingSubContainer>
                    </OutputSettingContainer>
                }
                {outputChannelArray.includes(Consts.RIP_HOTFOLDER) && activeRipIndex >= 0 && outputChannelArray[activeRipIndex] === Consts.RIP_HOTFOLDER &&
                 selectedUser && selectedUser.id && myRips.hasOwnProperty(selectedUser.id) && 
                 myRips[selectedUser.id].hasOwnProperty(Consts.RIP_HOTFOLDER) &&
                    <OutputSettingContainer style={largeContainerStyle}>
                        <OutputSettingSubContainer 
                            heading={t('outputChannels.hotFolder')}>
                            {myRips[selectedUser.id][Consts.RIP_HOTFOLDER].map((item, index) => {
                                return <span key={`${item}_${index}`}
                                    title={item.hotFolderPath}
                                    style={{ backgroundColor: (index === activeHotFolderIndex) ? Consts.SELECTION_COLOR : 'White' }}
                                    onClick={() => {
                                        setActiveHotFolderIndex(index);
                                    }}>
                                    {item.hotFolderName || item.hotFolderPath}</span>
                            }).filter(val => val)
                            }
                        </OutputSettingSubContainer>
                    </OutputSettingContainer>
                }
                {(outputChannelArray.includes(Consts.RIP_VERSAWORKS) || outputChannelArray.includes(Consts.RIP_HOTFOLDER)) && 
                    activeRipIndex >= 0 && (activePrinterIndex >= 0 || activeHotFolderIndex >= 0) &&
                    <OutputSettingContainer style={largeContainerStyle}>
                        <OutputSettingSubContainer 
                            heading={t('outputChannels.deviceSettings')}>
                            <DeviceSettingCheckBox
                                title={t('outputChannels.deviceSettings.rotation180')}
                                value={getOutputRotation()}
                                onChange={() => {
                                    const outputRotation = getOutputRotation();
                                    setMyRipsHandler(outputRotationProperty, Number(180 - outputRotation));
                                }}/>
                            <DeviceSettingCheckBox 
                                title={t('outputChannels.deviceSettings.referenceZeroPointLeft')}
                                value={getReferenceZeroPointAsLeft()}
                                onChange={() => {
                                    const referenceZeroPointAsLeft = getReferenceZeroPointAsLeft();
                                    setMyRipsHandler(referenceZeroPointAsLeftProperty, !referenceZeroPointAsLeft);
                                }}/>
                            <DeviceSettingCheckBox 
                                title={t('outputChannels.deviceSettings.autoCropping')}
                                value={getAutoCropping()}
                                onChange={() => {
                                    const autoCropping = getAutoCropping();
                                    setMyRipsHandler(autoCroppingProperty, !autoCropping);
                                }}/>
                            <DeviceSettingOption
                                name={t('outputChannels.printerTypes')}
                                action="printerType"
                                items={allPrinterTypes}
                                value={getPrinterType()} 
                                onChange={value => setMyRipsHandler(printerTypeProperty, value)}/>
                        </OutputSettingSubContainer>
                    </OutputSettingContainer>
                }
                {selectedUser && selectedUser.id && outputChannelArray.length === 0 &&
                    <div className={classes.instructions}>
                        <Instruction
                            value={t("users.outputStatus.noOutputConnectorInstalled")}/>
                        <Instruction 
                            style={{ marginTop: "1rem" }} 
                            link={Consts.CONNECTOR_OUTPUT_INSTALLER_LINK}
                            value={t('instruction.clickToDownloadAndInstall')}/>
                    </div>
                }
            </div>
            <div className={classes.actions}>
                <SecondaryButton onClick={onClose} label={t('button.cancel')} />
                <PrimaryButton label={t('button.save')} onClick={saveHandler} disabled={isSaveDisabled()}/>
            </div>
        </div>
    </Modal>);
}

export default OutputSettings;