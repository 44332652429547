import React, { useEffect, useRef, useState } from "react";
import IconButton from "../../components/Buttons/IconButton";
import InlineTextEditor from "../InlineTextEditor/InlineTextEditor";
import trashOutlineImage from "../../assets/trash-outline.svg";
import editOutlineImage from "../../assets/edit-outline.svg";
import Consts from "../../Consts";
import classes from "./ValueListNode.module.css";

export default function (props) {
    const ref = useRef();
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(props.value);
    const [saveEnabled, setSaveEnabled] = useState(true);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isEditing && ref.current && !ref.current.contains(e.target)) {
                setIsEditing(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isEditing]);

    useEffect(() => {
        setValue(value);
    }, [value])

    function valueChangedHandler(currentValue) {
        setSaveEnabled(!props.validator || props.validator(currentValue));
    }

    function saveHandler(currentValue) {
        setValue(currentValue);
        props.onEdit && props.onEdit(currentValue);
        setIsEditing(false);
    }

    return (
        <li style={props.isActive ? { "background": Consts.SELECTION_COLOR } : { "background": "" }} ref={ref}>
            <div className={classes.filterNode} onClick={e => props.onRowClick && props.onRowClick(e, value)} >
                <span title={value}>{value}</span>
                {isEditing &&
                    <InlineTextEditor onSave={saveHandler}
                        onClose={() => setIsEditing(false)}
                        saveEnabled={saveEnabled}
                        value={value}
                        onChange={valueChangedHandler} />
                }
                {!props.readOnly &&
                    <div className={classes.filterNodeBtns}>
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            setSaveEnabled(!props.validator || props.validator(value));
                            setIsEditing(true);
                        }} iconImage={editOutlineImage} />
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            props.onDelete && props.onDelete(e);
                        }} iconImage={trashOutlineImage} />
                    </div>
                }
            </div>
        </li>
    );
}