import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import nextId from "react-id-generator";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Modal from "../../components/Modal/Modal";
import Utils from "../../Utils";
import RuleItem from "./RuleItem";
import RuleList from "./RuleList";
import classes from './UserRules.module.css';
import { updateUser } from "./UsersActions";

function UserRules(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const allMasters = useSelector((state) => state.master.allMasters, shallowEqual);
    const qualityLevel = allMasters.qualityLevel;
    const location = allMasters.location;
    const printerType = allMasters.printerType;
    const mediaType = allMasters.mediaType;
    const colorConfig = allMasters.colorConfig;
    const allJigs = useSelector((state) => state.jigs.allJigs.jigs, shallowEqual) || [];
    const jigNames = allJigs.map((jig) => jig.name);
    const rules = {
        jig: jigNames,
        location: location,
        qualityLevel: qualityLevel,
        printerType: printerType,
        mediaType: mediaType,
        colorConfig: colorConfig
    };

    let selectedRulesIntialState = {};
    let intialIndex = -1;

    if (props.userDetails.userRules && props.userDetails.userRules.length > 0) {
        selectedRulesIntialState = props.userDetails.userRules[0];
        intialIndex = 0;
    }
    const [selectedRules, setSelectedRules] = useState(selectedRulesIntialState);
    const [showError, setShowError] = useState(false);
    const [ruleList, setRuleList] = useState(props.userDetails.userRules || []);
    const [activeIndex, setActiveIndex] = useState(intialIndex);

    useEffect(() => {
        if (activeIndex >= 0) {
            setRuleList((prevState) => {
                const temp = [...prevState];
                temp[activeIndex] = selectedRules;
                return temp;
            });
        }
    }, [selectedRules]);

    function ruleChangeHandler(rule, value, checked) {
        setSelectedRules((prevState) => {
            const obj = {
                ...prevState,
            }
            if (checked) {
                let temp = [...obj[rule]];
                temp.push(value);
                obj[rule] = temp;
            }
            else {
                let temp = [...obj[rule]];
                obj[rule] = temp.filter((item) => item !== value);
            }
            return obj;
        });
    }

    function saveHandler() {
        let data = {
            ...props.userDetails,
            userRules: ruleList,
        }
        //delete data.id
        updateUser(dispatch, data).then((response) => {
            if (response && response.data && response.data.success) {
                setShowError(false);
                props.responseHandler(true, t('successCard.message.rulesUpdated'));
                props.userRulesCloseHandler();
            }
            else {
                setShowError(true);
            }
        });
    }

    function errorDialogeCloseHandler() {
        setShowError(false);
    }

    function ruleListClickHandler(index) {
        setActiveIndex(index);
        setSelectedRules(ruleList[index]);
    }

    function ruleListAddHandler() {
        let obj = {
            "qualityLevel": [],
            "location": [],
            "printerType": [],
            "mediaType": [],
            "colorConfig": [],
            "jig": []
        };
        setActiveIndex(ruleList.length);
        setSelectedRules(obj);
        setRuleList((prevState) => {
            const temp = [...prevState];
            temp.push(selectedRules);
            return temp;
        });
    }
    function ruleListCopyHandler(index) {
        setActiveIndex(ruleList.length);
        setSelectedRules(ruleList[index]);
        setRuleList((prevState) => {
            const temp = [...prevState];
            temp.push(ruleList[index]);
            return temp;
        });
    }

    function ruleListDeleteHandler(index) {
        if (index === 0 && ruleList.length > 1) {
            setSelectedRules(ruleList[1]);
            setActiveIndex(0);
        }
        else if (ruleList.length > 1) {
            setSelectedRules(ruleList[0]);
            setActiveIndex(0);
        } else {
            setSelectedRules({});
            setActiveIndex(-1)
        }
        setRuleList((prevState) => {
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    return (
        <>
            {showError && Utils.showErrorDialog(t('userRules.error.updateFailed'), errorDialogeCloseHandler)}
            <Modal onClose={() => props.userRulesCloseHandler()} title={`${t('userRules.heading')} ( ${props.userDetails.userId} )`}>
                <div className={classes.main}>
                    <div className={classes.leftContainer}>
                        <div className={classes.leftHeader}>
                            <p>{t('userRules.ruleMessage')}</p>
                            <PrimaryButton label={t('button.addRule')} onClick={ruleListAddHandler} />
                        </div>
                        <div className={classes.rulesTable}>
                            <table className='table'>
                                <thead >
                                    <tr>
                                        <th style={{ "width": "20%" }}>{t('userRules.id')}</th>
                                        <th style={{ "width": "40%", "textAlign": "left" }}>{t('userRules.name')}</th>
                                        <th style={{ "width": "40%" }}>{t('userRules.action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ruleList && ruleList.map((rule, index) => {
                                        return <RuleList key={nextId()}
                                            index={index} activeIndex={activeIndex}
                                            onClick={ruleListClickHandler}
                                            onDelete={ruleListDeleteHandler}
                                            onCopy={ruleListCopyHandler} />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={classes.rightContainer}>
                        {activeIndex >= 0 && <div className={classes.rightHeader}>
                            <h3>{String.format(t('ruleList.name'), activeIndex + 1)}</h3>
                        </div>
                        }
                        <div className={classes.ruleItems}>
                            {ruleList && ruleList.length > 0 && rules && Object.keys(rules).map((key) => {
                                return <RuleItem key={key} ruleKey={key} ruleValues={rules[key]} onChange={ruleChangeHandler} selectedRules={selectedRules} />
                            })}
                        </div>
                        <div className={classes.actions}>
                            <SecondaryButton onClick={() => props.userRulesCloseHandler()} label={t('button.cancel')} />
                            <PrimaryButton onClick={saveHandler} label={t('button.save')} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );

}

export default UserRules;