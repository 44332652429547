const Consts = {
    // Drawing colors
    TABLE_FILL_COLOR: "#EEE",
    TABLE_STROKE_COLOR: "#707070",
    JIG_FILL_COLOR: "#BFBFBF",
    JIG_STROKE_COLOR: "#707070",
    SLOT_FILL_COLOR: "#C2B5A4",
    FOCUSED_SLOT_FILL_COLOR: "#AB9A83",
    SLOT_STROKE_COLOR: "#707070",
    IMAGE_BOX_FILL_COLOR: "#D8BFD8",
    FOCUSED_IMAGE_BOX_FILL_COLOR: "#B788B7",
    IMAGE_BOX_STROKE_COLOR: "#707070",
    TABLE_PRINTABLE_STROKE_COLOR: "#707070",
    TABLE_PRINTABLE_FILL_COLOR: "none",
    TEXT_COLOR: "#000",
    STROKE_COLOR_WARN: "red",

    // Storage keys
    STORAGE_KEY_ACTIVE_COLUMNS: "activeColumns",
    STORAGE_KEY_HISTORY_COLUMNS: "historyColumns",

    // Error codes for job
    JOB_ERROR_NO_JIG: "NO-JIG",
    JOB_ERROR_NONE: "NONE",

    // Other Error codes
    ERROR_CUSTOMER_ALREADY_EXISTS: "customer_already_exists",
    ERROR_SYSTEM_KEYWORD: "system_keyword",
    ERROR_INVALID_ENVIRONMENT_NAME: "invalid_resource_name",

    // Supported inputs
    INPUT_PERSBIZ: "PersBiz",
    INPUT_HOTFOLDER: "HotFolder",

    // Supported rips
    RIP_VERSAWORKS: "VersaWorks",
    RIP_ERGOSOFT: "ErgoSoft",
    RIP_HOTFOLDER: "LocalHotFolder",
    
    // Selection colors
    SELECTION_COLOR: "#EAEAEA",
    SECONDARY_COLOR: "#F2F2F2",
    
    // Permissions
    PERMISSIONS_JIG: 'jig',
    PERMISSIONS_PRINT_LAYOUT: 'print_layout',
    PERMISSIONS_AUTOMATION_RULES: 'automation_rules',
    PERMISSIONS_USER_MANAGEMENT: 'user_management',
    PERMISSIONS_MASTERS: 'masters',
    PERMISSIONS_INPUT_SETTINGS: 'input_settings',
    PERMISSIONS_OUTPUT_SETTINGS: 'output_settings',
    PERMISSIONS_CONFIGURATION: 'configuration',
    PERMISSIONS_DELETE_ORDERS: 'delete_orders',
    PERMISSIONS_CHANGE_ORDER_JIG: 'change_order_jig',
    PERMISSIONS_DOWNLOADS: 'downloads',
    PERMISSIONS_CHANGE_ORDER_ASSIGNEE: 'change_order_assignee',
    PERMISSIONS_PRINT_ORDER: 'print_order',
    PERMISSIONS_OUTPUT_PRESET: 'output_preset',

    // Hot Folder Count
    HOT_FOLDER_COUNT: 8,

    // Multi Job Grouping Types
    MULTIJOB_DUPLICATE: "Duplicate",
    MULTIJOB_COMBINED: "Combined",
    MULTIJOB_ORIGINAL_FILE: "Original File",
    MULTIJOB_DUPLICATE_FILE: "Dup. File",
    MULTIJOB_COMBINED_FILE: "Comb. File",

    // Links
    CONNECTOR_INPUT_INSTALLER_LINK: "/pax/global/connectors/PrintAutoMate_InputManager_1.0_Setup.exe",
    CONNECTOR_OUTPUT_INSTALLER_LINK: "/pax/global/connectors/PrintAutoMate_OutputManager_1.0_Setup.exe",
    SETUP_GUIDE_LINK: "https://support.rolanddg.com/hc/en-us",

    // Default Values
    DEFAULT_PRINTER_TYPE: "default", 
    DEFAULT_OUTPUT_ROTATION: 0, 
    DEFAULT_REFERENCE_ZERO_POINT_AS_LEFT: false, 
    DEFAULT_AUTO_CROPPING: true, 
    DEFAULT_PRINTER_SETTING_FILES: []
};

export default Consts;