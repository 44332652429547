import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal/Modal";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import classes from './OutputPreset.module.css';
import LayoutSelection from "./LayoutSelection";
import ChannelSelection from "./ChannelSelection";
import NameSelection from "./NameSelection";

function validatePreset(preset) {
    if (!preset.table || !preset.outputChannel || !preset.name || !preset.files?.length) {
        return false;
    }
    return true;
}

export default function UpdateOutputPreset({onClose, onSave, userTables = [], outputChannels = [], preset = {}}) {
    const { t } = useTranslation();
    const [outputPreset, setOutputPreset] = useState(preset);
    
    return (<Modal title={t('orders.presets.heading')} onClose={onClose}>
        <div className={classes.main}>
            <div className={classes.container}>
                <LayoutSelection userTables={userTables} 
                    outputPreset={outputPreset}
                    onInfoChange={e => setOutputPreset(s => ({...s, ...e}))}
                />
                <ChannelSelection outputChannels={outputChannels} 
                    outputPreset={outputPreset}
                    onInfoChange={e => setOutputPreset(s => ({...s, ...e}))}
                />
                <NameSelection outputPreset={outputPreset}
                    onInfoChange={e => setOutputPreset(s => ({...s, ...e}))}
                />
            </div>
            <div className={classes.actions}>
                <SecondaryButton onClick={onClose} label={t('button.cancel')} />
                <PrimaryButton label={t('button.save')} disabled={!validatePreset(outputPreset)} onClick={e => {
                    onSave && onSave(outputPreset);
                    onClose && onClose(e);
                }} />
            </div>
        </div>
    </Modal>);
}