import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextButton from "../Buttons/TextButton";
import Modal from "../../components/Modal/Modal";
import classes from "./AddValueModal.module.css";

function AddValueModal(props) {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const [addEnabled, setAddEnabled] = useState(false);
    const [showError, setShowError] = useState(false);

    function valueChangedHandler(e) {
        setValue(e.target.value);

        const newValue = e.target.value.trim();
        if (newValue === "") {
            setAddEnabled(false);
            setShowError(false);
        }
        else {
            if (props.validator && !props.validator(newValue)) {
                setAddEnabled(false);
                setShowError(true);
            }
            else if (props.disallowedValues && props.disallowedValues.includes(newValue.toLowerCase())) {
                setAddEnabled(false);
                setShowError(true);
            }
            else {
                setShowError(false);
                setAddEnabled(true);
            }
        }
    }

    return (
        <Modal title={t('addValue.heading')} onClose={props.onClose}>
            <div>
                <div className={classes.addValue}>
                    <div>
                        <input type="text" name="value" onChange={valueChangedHandler} value={value} autoComplete="off" />
                    </div>
                    {showError && <div><span style={{ "color": "red", "marginLeft": "0.7rem" }}> {t('addValue.error.alreadyExists')}</span></div>}
                </div>
                <div className={classes.buttons}>
                    <TextButton label={t('button.cancel').toUpperCase()} onClick={props.onClose} />
                    <TextButton label={t('button.add').toUpperCase()} onClick={() => props.onAdd(value.trim())} disabled={!addEnabled} />
                </div>
            </div>
        </Modal>
    );
}

export default AddValueModal;
