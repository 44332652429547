import React from 'react';
import { useTranslation } from "react-i18next";
import ParameterMapping from './ParameterMapping';
import classes from './PersBiz.module.css';

function PersBiz(props) {
  const { t } = useTranslation();

  return (
    <div className={classes.main}>
      <div className={classes.containerHeader}>
        <h3>{t('inputSettings.parameterMapping.heading')}</h3>
      </div>
      <div className={classes.content}>
        <ParameterMapping mapping={props.mapping}
          onChange={props.onChangeMapping}
          suggestions={props.suggestions} />
      </div>
    </div>
  );
}

export default PersBiz;