import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import Item from "./Item";
import Modal from "../Modal/Modal";
import classes from "./SelectItems.module.css";

function SelectItems(props) {
    const { t } = useTranslation();

    const allItems = props.allItems || {};
    const [selectedItems, setSelectedItems] = useState(props.selectedItems ? [...props.selectedItems] : []);

    function onChangeHandler(item) {
        setSelectedItems((prevState) => {
            let newState = [...prevState];
            if (newState.includes(item)) {
                newState = newState.filter(e => e !== item);
            } else {
                newState.push(item);
            }
            return newState;
        });
    }

    return (
        <Modal title={props.title} onClose={props.onClose}>
            <div className={classes.main}>
                <div className={classes.body}>
                    {Object.keys(allItems).map((item) => {
                        return <Item 
                                key={item}
                                title={allItems[item]}
                                value={selectedItems.includes(item)} 
                                onChange={() => {onChangeHandler(item)}}
                            />
                    })}
                </div>
                <div className={classes.actions}>
                    <SecondaryButton onClick={props.onClose} label={t("button.cancel")} />
                    <PrimaryButton label={t("button.ok")} onClick={() => {props.onSave(selectedItems)}}/>
                </div>
            </div>
        </Modal>
    )
}

export default SelectItems;