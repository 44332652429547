import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allOutputPresets: []
};

const outputPresetsSlice = createSlice({
    name: "outputPresets",
    initialState,
    reducers: {
        fetchOutputPresets: (state, action) => {
            state.allOutputPresets = action.payload.outputPresets;
        },
        updateOutputPreset: (state, action) => {
            const presetData = action.payload.outputPreset.presetData;
            const orderData = action.payload.outputPreset.orderData;
            if (presetData.length) {
                presetData.forEach((preset) => {
                    const index = state.allOutputPresets.findIndex(
                        (item) => item.id === preset.id
                    );
                    if (!orderData && preset.tableData) {
                        delete preset.tableData;
                    }
                    state.allOutputPresets.splice(index, 1, preset);
                });
            }
        },
        removeTableData: (state, action) => {
            const presetId = action.payload.presetId;
            const index = state.allOutputPresets.findIndex(
                (item) => item.id === presetId
            );
            state.allOutputPresets[index].tableData = null;
        }
    },
});

export const outputPresetsActions = outputPresetsSlice.actions;

export default outputPresetsSlice.reducer;
