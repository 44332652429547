import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Tabs from "../Tabs/Tabs";
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import classes from "./Header.module.css";
import keyIcon from "../../assets/change-password.svg";
import setupGuideIcon from "../../assets/setup-guide-outline.svg";
import logoutIcon from '../../assets/logout.svg';
import PasswordManager from '../../modules/CurrentUser/PasswordManager';
import SuccessCard from '../SuccessCard/SuccessCard';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import DropDownItem from '../DropDownMenu/DropDownItem';
import { useSelector } from 'react-redux';
import Consts from '../../Consts';

export default function Header(props) {
  const { t } = useTranslation();
  const location = useLocation();

  const [showPasswordManager, setShowPasswordManager] = useState(false);
  const [showSuccessCard, setShowSuccessCard] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const currentUser = useSelector(state => state.users.allUsers.currentUser || {});

  function responseHandler(success, message) {
    setSuccessMessage(message);
    setShowSuccessCard(success);
  }

  function successCardCloseHandler() {
    setShowSuccessCard(false);
  }

  return (
    <div>
      {showPasswordManager && <PasswordManager passwordManagerCloseHandler={() => setShowPasswordManager(false)} responseHandler={responseHandler} />}
      {showSuccessCard && <SuccessCard message={successMessage} onClose={successCardCloseHandler} btnLabel={t('button.ok')} />}
      <div className="headerMain">
        <div className="headerLogo"></div>
        {props.isAuth && props.isB2c && 
          <div className={classes.tabs}>
            <Tabs activeTab={location.pathname.includes("settings") ? "1" : "0"}>
              <div label={t("header.tab.orders")} link={`${props.url}/orders`} />
              <div label={t("header.tab.settings")} link={`${props.url}/settings`} />
            </Tabs>
          </div>
        }
        <div>
          {props.isAuth ?
            <div className={classes.logoutContainer}>
              <DropDownMenu title={currentUser?.userId}>
                <DropDownItem title={t('dropdownItem.changePassword')} onClick={() => setShowPasswordManager(true)} imageIcon={keyIcon} />
                <DropDownItem title={t('dropdownItem.setupGuide')} onClick={()=> window.open(Consts.SETUP_GUIDE_LINK, "_blank")} imageIcon={setupGuideIcon} />
                <DropDownItem title={t('dropdownItem.logout')} onClick={props.logoutUser} imageIcon={logoutIcon} />
              </DropDownMenu>
            </div> : <LanguageSelector />
          }
        </div>
      </div>
    </div>
  );
} 