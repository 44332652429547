import axios from '../../axios';
import { configActions } from "./ConfigSlice";

export function getConfig(dispatch) {
  return axios({
    url: "/pax/config",
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(configActions.getConfig({ config: data.configData || {} }));
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function updateConfig(dispatch, data) {
  return axios({
    url: "/pax/config",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(configActions.updateConfig({ config: data }));
      }
      return response;
    }).catch((error) => {
      return error;
    });
}

export function updateShowHelpMessage(dispatch, showHelpMessage) {
  const data = {
    showHelpMessage
  };
  return axios({
    url: "/pax/config/updateShowHelpMessage",
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(configActions.updateConfig({ config: data }));
      }
      return response;
    }).catch((error) => {
      return error;
    });
}

export function updateHelpMessage(dispatch, helpMessage) {
  const data = {
    helpMessage
  };
  return axios({
    url: "/pax/config/updateHelpMessage",
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(configActions.updateConfig({ config: data }));
      }
      return response;
    }).catch((error) => {
      return error;
    });
}