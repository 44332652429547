import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { addMaster } from "../../modules/Master/MasterActions";
import editOutlineImage from "../../assets/edit-outline.svg";
import IconButton from "../Buttons/IconButton";
import TextButton from "../Buttons/TextButton";
import AddValueModal from "../AddValueModal/AddValueModal";
import SelectItems from "../SelectItems/SelectItems";
import Utils from "../../Utils";
import classes from "./MultiSelectActionItem.module.css";

function MultiSelectActionItem(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const items = props.items ? props.items : [];
    const selectedItems = props.selectedItems ? props.selectedItems : [];
    const [showAddValue, setShowAddValue] = useState(false);
    const [showSelectItems, setShowSelectItems] = useState(false);
    const [showError, setShowError] = useState(false);
    const disallowedValues = Object.values(items).map((item) => item.toLowerCase());

    function addValueHandler(actionType) {
        setShowAddValue(true);
    }

    function closeAddValueHandler() {
        setShowAddValue(false);
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    function addNewValueHandler(value) {
        setShowAddValue(false);

        const master = {
            type: props.action,
            value: value
        };

        addMaster(dispatch, master).then((response) => {
            if (response && response.data && response.data.success) {
                props.onChange([...selectedItems, value]);
            }
            else {
                setShowError(true);
            }
        });
    }

    function getActionValue(list) {
        if (!list || list.length === 0) {
            return t('items.notAssigned');
        } else if (list.length === 1) {
            return items[list[0]];
        } else {
            return t('items.multiple');
        }
    }

    const actionValue = getActionValue(selectedItems);

    return (<>
        {showError && Utils.showErrorDialog(t('extendFilter.error.addValueFailed'), closeErrorHandler)}
        {showAddValue && <AddValueModal disallowedValues={disallowedValues} onAdd={addNewValueHandler} onClose={closeAddValueHandler} />}
        {showSelectItems && 
            <SelectItems 
                title={props.selectItemsTitle}
                allItems={items} 
                selectedItems={selectedItems} 
                onSave={(e) => {
                    props.onChange(e); 
                    setShowSelectItems(false);
                }} 
                onClose={()=> setShowSelectItems(false)}/>
        }
        <div className={classes.main} style={props.style}>
            <div className={classes.label}>
                <label>{props.name}:</label>
            </div>
            <div className={classes.item}>
                <span title={actionValue}>{actionValue}</span>
                <div className={classes.actions}>
                    <IconButton 
                        onClick={() => setShowSelectItems(true)} 
                        iconImage={editOutlineImage} />
                </div>
            </div>
            <div className={classes.addValue}>
                <TextButton label={t('button.addValue')} onClick={addValueHandler}/>
            </div>
        </div>
    </>
    )
}

export default MultiSelectActionItem;