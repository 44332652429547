import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allTables: { tables: [], selectedIndex: 0 },
  userTables: { tables: [] }
};

const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    fetchTables: (state, action) => {
      state.allTables.tables = action.payload.tables;
    },
    fetchUserTables: (state, action) => {
      state.userTables.tables = action.payload.tables;
    },
    createTable: (state, action) => {
      state.allTables.tables.push(action.payload.table);
      state.allTables.selectedIndex = state.allTables.tables.length - 1;
    },
    updateTable: (state, action) => {
      const index = state.allTables.tables.findIndex(
        (item) => item.id === action.payload.table.id
      );
      state.allTables.tables.splice(index, 1, action.payload.table);
      state.allTables.selectedIndex = index;
    },
    deleteTable: (state, action) => {
      const index = state.allTables.tables.findIndex(
        (item) => item.id === action.payload.id
      );
      state.allTables.tables.splice(index, 1);
      if (state.allTables.tables.length) {
        state.allTables.selectedIndex = 0;
      }
      else {
        state.allTables.selectedIndex = -1;
      }
    },
    updateTableList: (state, action) => {
      const tableIds = action.payload.data.tableIds;
      const currentJigId = action.payload.data.currentJigId;
      const newJigId = action.payload.data.newJigId;
      state.allTables.tables.forEach((table) => {
        if (tableIds.includes(table.id)) {
          const jigs = table.jigs;
          jigs.forEach((jig) => {
            if (jig.id === currentJigId) {
              jig.id = newJigId;
            }
          });
        }
      });
    }
  }
});

export const tablesActions = tablesSlice.actions;

export default tablesSlice.reducer;
