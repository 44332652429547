import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual } from 'react-redux';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';

function AssignedUsers(props) {
    const allUsers = useSelector((state) => state.users.allUsers, shallowEqual) || [];
    const { t } = useTranslation();

    const options = allUsers.users.filter(user => 
            user.role.toLowerCase() !== 'admin'
        ).map(user => ({ value: user.id, label: user.userId }));

    return (<>
        <p>{t("editOrder.assignedTo.label")}</p>
        <MultiSelect 
            value={props.assignedUsers}
            options={options}
            onChange={user => props.onChange && props.onChange(user)}
        />
    </>
    );
}

export default AssignedUsers;