import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import BackButton from '../../components/Buttons/BackButton';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Confirm from '../../components/Confirm/Confirm';
import SuccessCard from '../../components/SuccessCard/SuccessCard';
import Utils from '../../Utils';
import EditUser from './EditUser';
import UserRules from './UserRules';
import UserItem from './UserItem';
import classes from './Users.module.css';
import { deleteUser } from './UsersActions';
import OrderUtils from '../Orders/components/OrderUtils';
import { updateOutputPreset } from '../Orders/OutputPresetActions';
import Consts from '../../Consts';
import Instruction from '../../components/Instruction/Instruction';
import PrintPanel from '../Orders/components/PrintPanel';

function Users(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tableEndRef = useRef();
    const tableStartRef = useRef();

    const allUsers = useSelector((state) => state.users.allUsers, shallowEqual) || [];
    const allOutputChannels = useSelector((state) => state.outputChannels.allOutputChannels, shallowEqual) || [];
	const allOutputPresets = useSelector((state) => state.outputPresets.allOutputPresets, shallowEqual) || [];

    const [activeRow, setActiveRow] = useState(0);
    const [selectedUser, setSelectedUser] = useState(allUsers.users[0]);
    const [showEditUser, setShowEditUser] = useState(false);
    const [showUserRules, setShowUserRules] = useState(false);
    const [editType, setEditType] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessCard, setShowSuccessCard] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    
    const [outputPresetIndex, setOutputPresetIndex] = useState(-1);
    const [outputPresets, setOutputPresets] = useState([]);
    const [outputChannels, setOutputChannels] = useState([]);

    useEffect(() => {
        if (allOutputPresets && selectedUser) {
            const id = selectedUser.id;
            setOutputPresets(allOutputPresets.filter(p => p.userId === id));
            setOutputChannels(allOutputChannels.filter(outputChannel => outputChannel.userId === id))
        }
    }, [selectedUser, allOutputChannels, allOutputPresets])

    useEffect(() => {
        setSelectedUser(allUsers.users[allUsers.selectedIndex]);
        setActiveRow(allUsers.selectedIndex);
    }, [allUsers]);

    function scrollToBottom() {
        tableEndRef.current.scrollIntoView({ behavior: 'smooth', block: "end" });
    }

    function scrollToTop() {
        tableStartRef.current.scrollIntoView({ behavior: 'smooth', block: "end" });
    }

    function addUserHandler() {
        setSelectedUser({
            location: "default"
        });
        setEditType("NEW");
        setShowEditUser(true);
    }

    function editUserCloseHandler() {
        setShowEditUser(false);
    }

    function userRulesCloseHandler() {
        setShowUserRules(false);
    }

    function selectUserHandler(user, index) {
        setSelectedUser(user);
        setActiveRow(index);
    }

    function editUserPassHandler(user, index) {
        setSelectedUser(user);
        setActiveRow(index);
        setEditType("PASSWORD");
        setShowEditUser(true);
    }

    function editUserHandler(user, index) {
        setSelectedUser(user);
        setActiveRow(index);
        setEditType("EDIT");
        setShowEditUser(true);
    }

    function editUserRuleHandler(user, index) {
        setSelectedUser(user);
        setActiveRow(index);
        setShowUserRules(true);
    }

    function deleteUserHandler(user) {
        setSelectedUser(user);
        setShowConfirmation(true);
    }

    function confirmUserDeleteHandler() {
        setShowConfirmation(false);
        deleteUser(dispatch, selectedUser).then((response) => {
            if (response && response.data && response.data.success) {
                setShowError(false);
                scrollToTop();
            }
            else {
                setErrorMessage(t('users.error.deleteFailed'));
                setShowError(true);
            }
        });
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    function responseHandler(success, message) {
        setSuccessMessage(message);
        setShowSuccessCard(success);
    }

    function successCardCloseHandler() {
        setShowSuccessCard(false);
    }
    
    const totalOutputChannels = new Map();
    let isSingleHost = OrderUtils.checkSingleHost(outputChannels);
    outputChannels.forEach(outputChannel => {
        let obj = {
            ...outputChannel,
            name: OrderUtils.getChannelName(outputChannel, isSingleHost)
        };
        totalOutputChannels.set(outputChannel.id, obj);
    });

    function getOutputStatus(id) {
        
        const channels = allOutputChannels.filter((outputChannel) => outputChannel.userId === id);
        if (channels.length === 0) {
            return t("users.outputStatus.noOutputConnectorInstalled");
        }

        const outputPresets = allOutputPresets.filter((outputPreset) => outputPreset.userId === id);
        let hasPresets = false;

        if (outputPresets.length > 0) {
            for (let ind = 0; ind < outputPresets.length; ind++) {
                if (outputPresets[ind].tableId && outputPresets[ind].outputChannelId) {
                    hasPresets = true;
                    break;
                }
            }
        }

        if (hasPresets === false) {
            return t("users.outputStatus.noPresetsDefined");
        }

        return "";
    }

	function updateHandler(outputPreset, removeOrder) {
		if (outputPresetIndex >= 0) {
			updateOutputPreset(dispatch, { presetData: [outputPreset] }).then((response) => {
				if (response && response.data && response.data.success) {
					setShowError(false);
				}
				else {
					if (removeOrder) {
						setErrorMessage(t('orders.error.removeOrdersFailed'));
					} else {
						setErrorMessage(t('orders.error.updatePresetFailed'));
					}
					setShowError(true);
				}
			});
		}
	}

    function toShowOutputConnectorInstallerLink() {
        if (selectedUser && selectedUser.id && 
                getOutputStatus(selectedUser.id) === t("users.outputStatus.noOutputConnectorInstalled")) {
                    return true;
        }
        return false;
    }

    return (<>
        {showEditUser && <EditUser editUserCloseHandler={editUserCloseHandler} editType={editType} userDetails={selectedUser} responseHandler={responseHandler} scrollToBottom={scrollToBottom} />}
        {showUserRules && <UserRules userRulesCloseHandler={userRulesCloseHandler} userDetails={selectedUser} responseHandler={responseHandler} />}
        {showError &&
            Utils.showErrorDialog(errorMessage, closeErrorHandler)}
        {showSuccessCard && <SuccessCard message={successMessage} onClose={successCardCloseHandler} btnLabel={t('button.ok')} />}
        {showConfirmation &&
            <Confirm onClose={() => setShowConfirmation(false)} onConfirm={confirmUserDeleteHandler} title={t('button.delete')} message={t('users.delete.message')} confirmLabel={t('button.delete')} cancelLabel={t('button.cancel')} />}
        <div className={classes.main}>
            <div className={classes.leftContainer}>
                <div className={classes.backButton}>
                    <BackButton onBack={() => props.history.goBack()} />
                </div>
                <div className={classes.container}>
                    <h2> {t('users.heading')} </h2>
                    <div className={classes.addUserBtn}><PrimaryButton onClick={addUserHandler} label={t('button.newUser')} /> </div>
                </div>
                <div className={classes.usersTable}>
                    <div ref={tableStartRef} />
                    <table className='table'>
                        <thead >
                            <tr>
                                <th></th>
                                <th style={{ "width": "6%", "textAlign": "left" }}>{t('users.id')}</th>
                                <th style={{ "width": "44%", "textAlign": "left" }}>{t('users.name')}</th>
                                <th style={{ "width": "20%" }}>{t('users.role')}</th>
                                <th style={{ "width": "30%" }}>{t('users.action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allUsers && allUsers.users.map((user, index) => {
                                return (
                                    <UserItem user={user} key={user.id} index={index}
                                        onSelect={selectUserHandler} onEditPassword={editUserPassHandler} onEdit={editUserHandler} onDelete={deleteUserHandler}
                                        onEditRule={editUserRuleHandler}
                                        isActive={activeRow === index}
                                        outputStatus={getOutputStatus(user.id)} />
                                );
                            })}
                        </tbody>
                    </table>
                    <div ref={tableEndRef} />
                </div>
            </div>
            <div className={classes.rightContainer}>
                {
                    selectedUser && selectedUser.id && selectedUser.role &&
                    !Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(selectedUser) && outputChannels.length > 0 ?
                    <PrintPanel outputPresets={outputPresets} 
                        selectedUser={selectedUser}
                        allOutputChannels={outputChannels}
                        hidePrintBtn={true}
                        onSelectChange={setOutputPresetIndex}
                        onPresetUpdate={updateHandler}
                        onError={e => {
                            setErrorMessage(e.msg);
                            setShowError(true);
                        }}
                        showLayout
                    />
                    : 
                    <div className={classes.rightContainerStatusContent}>
                        <Instruction
                            style={{width: "30%"}}  
                            value={getOutputStatus(selectedUser?.id)}/>
                        {toShowOutputConnectorInstallerLink() &&
                            <Instruction
                                style={{width: "30%", marginTop: "1rem"}} 
                                link={Consts.CONNECTOR_OUTPUT_INSTALLER_LINK}
                                value={t('instruction.clickToDownloadAndInstall')}/>
                        }
                    </div>
                }
            </div>
        </div>
    </>);
}

export default Users;