import { createSlice } from "@reduxjs/toolkit";
import Utils from "../../Utils";

const initialState = {
  allOrders: [],
  historyOrders: []
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    fetchOrders: (state, action) => {
      let temp = [];
      action.payload.orders.forEach((order) => {
        let jobs = order.jobs;
        let date = Utils.getLocalDate(order.date);
        jobs.forEach((job, index) => {
          let frames = job.frames;
          let activeframeIndex = 0;
          for (let i = 0; i < frames.length; i++) {
            if (frames[i].remaining) {
              activeframeIndex = i;
              break;
            }
          }
          let mediaInfo = job.mediaInfo;
          let orderId = order.orderId;
          let activeFrame = frames[activeframeIndex] || {};

          let sizeInfo = activeFrame.sizeInfo || job.sizeInfo;
          let dealerOrderId = order.dealerOrderId;
          let externalOrderId = order.externalOrderId || "";
          let customerInfo = order.customerInfo || {};
          let obj = {
            frames: frames,
            id: order.id + job.jobId,
            objId: order.id,
            orderId: orderId,
            externalOrderId: externalOrderId,
            customerInfo: customerInfo,
            dealerOrderId: dealerOrderId,
            jobId: job.jobId,
            frameId: activeFrame.frameId,
            frameIndex: activeframeIndex,
            jobStatus: job.jobStatus,
            date: date,
            mediaSize: mediaInfo.mediaSize,
            sku: mediaInfo.sku,
            mediaName: mediaInfo.mediaName,
            mediaColor: mediaInfo.mediaColor,
            additionalParams: job.additionalParams,
            jobSizeInfo: job.sizeInfo,
            physicalWidth: parseFloat(sizeInfo.widthMM),
            physicalHeight: parseFloat(sizeInfo.heightMM),
            copies: activeFrame.copies,
            remaining: activeFrame.remaining,
            amountPrinted: parseInt(activeFrame.copies) - parseInt(activeFrame.remaining),
            pending: activeFrame.pending,
            assigned: parseInt(activeFrame.remaining) - parseInt(activeFrame.pending),
            thumbnailPath: activeFrame.thumbnailPath,
            filePath: activeFrame.filePath,
            fileType: activeFrame.fileType,
            jig: job.jig,
            location: job.location,
            qualityLevel: job.qualityLevel,
            printerType: job.printerType,
            mediaType: job.mediaType,
            colorConfig: job.colorConfig,
            users: job.users
          }
          temp.push(obj);
        });
      });
      state.allOrders = temp;
    },
    createOrder: (state, action) => {
      state.allOrders.push(action.payload.order);
    },
    updateOrder: (state, action) => {
      const index = state.allOrders.findIndex(
        (item) => item.id === action.payload.order.id + action.payload.order.jobId
      );
      state.allOrders[index].jig = action.payload.order.jig;
      state.allOrders[index].copies = action.payload.order.copies;
      state.allOrders[index].remaining = action.payload.order.remaining;
      state.allOrders[index].amountPrinted = action.payload.order.copies - action.payload.order.remaining;
      state.allOrders[index].pending = action.payload.order.remaining;
      state.allOrders[index].assigned = 0;
      state.allOrders[index].users = action.payload.order.users;
    },

    deleteOrder: (state, action) => {
      const index = state.allOrders.findIndex(
        (item) => item.id === action.payload.order.id + action.payload.order.jobId
      );
      if (index !== -1) {
        state.allOrders.splice(index, 1);
      } else {
        const index = state.historyOrders.findIndex(
          (item) => item.id === action.payload.order.id + action.payload.order.jobId
        );
        state.historyOrders.splice(index, 1);
      }
    },

    printOrder: (state, action) => {
      const orders = action.payload.orders;

      orders.forEach((order) => {
        const index = state.allOrders.findIndex(
          (item) => (item.orderId === order.orderId && item.jobId === order.jobId && item.frameIndex === order.frameIndex)
        );
        if (state.allOrders[index].remaining - parseInt(order.assigned)) {
          state.allOrders[index].jobStatus = "TOPRINT";
        }
        if (state.allOrders[index].assigned - parseInt(order.assigned)) {
          state.allOrders[index].jobStatus = "PRINTING";
        }
        let activeFrame;
        if ((state.allOrders[index].remaining - parseInt(order.assigned) === 0) && (state.allOrders[index].frames.length - 1 !== state.allOrders[index].frameIndex)) {
          const order = JSON.parse(JSON.stringify(state.allOrders[index]));
          let activeframeIndex = order.frameIndex + 1;
          order.jobStatus = "TOPRINT";
          order.frameIndex = activeframeIndex;
          const frames = order.frames;
          activeFrame = frames[activeframeIndex];

          const copies = activeFrame.copies;
          const sizeInfo = activeFrame.sizeInfo || order.jobSizeInfo;
          let obj = {
            ...order,
            copies: copies,
            remaining: copies,
            amountPrinted: 0,
            pending: copies,
            assigned: 0,
            thumbnailPath: activeFrame.thumbnailPath,
            filePath: activeFrame.filePath,
            fileType: activeFrame.fileType,
            physicalWidth: parseFloat(sizeInfo.widthMM),
            physicalHeight: parseFloat(sizeInfo.heightMM),
          }
          state.allOrders.splice(index, 1, obj);
        }

        else if ((state.allOrders[index].remaining - parseInt(order.assigned) === 0) && (state.allOrders[index].frames.length - 1 === state.allOrders[index].frameIndex)) {
          const order = JSON.parse(JSON.stringify(state.allOrders[index]));
          let activeframeIndex = 0;
          order.jobStatus = "PRINTED";
          order.frameIndex = 0;
          const frames = order.frames;
          frames.forEach((frame) => {
            frame.remaining = 0;
            frame.pending = 0;
            frame.assigned = 0;
          });
          activeFrame = frames[activeframeIndex];
          const copies = activeFrame.copies;
          const sizeInfo = activeFrame.sizeInfo || order.jobSizeInfo;
          let obj = {
            ...order,
            copies: copies,
            remaining: 0,
            amountPrinted: copies,
            pending: 0,
            assigned: 0,
            thumbnailPath: activeFrame.thumbnailPath,
            filePath: activeFrame.filePath,
            fileType: activeFrame.fileType,
            physicalWidth: parseFloat(sizeInfo.widthMM),
            physicalHeight: parseFloat(sizeInfo.heightMM),
          }
          const historyIndex = state.historyOrders.findIndex(
            (item) => (item.orderId === order.orderId)
          );

          if (historyIndex >= 0) {
            state.historyOrders.splice(historyIndex + 1, 0, obj);
          } else {
            state.historyOrders.push(obj);
          }
          state.allOrders.splice(index, 1);
        }
        else {
          state.allOrders[index].remaining = state.allOrders[index].remaining - parseInt(order.assigned);
          state.allOrders[index].amountPrinted = state.allOrders[index].amountPrinted + parseInt(order.assigned);
          state.allOrders[index].assigned = parseInt(state.allOrders[index].assigned) - parseInt(order.assigned);
        }
      });
    },

    assignOrder: (state, action) => {
      const orderData = action.payload.data;
      for (let i = 0; i < orderData.length; i++) {
        const item = orderData[i];
        state.allOrders.some((order) => {
          if (item.orderId === order.orderId && item.jobId === order.jobId && item.frameIndex === order.frameIndex) {
            order.pending = parseInt(item.pending);
            order.assigned = parseInt(order.remaining) - parseInt(item.pending);
            order.jobStatus = "PRINTING";
            return true;
          }
          return false;
        });
      }
    },

    removeAssignmentFromOrders: (state, action) => {
      const assignedOrders = action.payload.data;
      for (let i = 0; i < assignedOrders.length; i++) {
        const item = assignedOrders[i];
        state.allOrders.some((order) => {
          if (item.orderId === order.orderId && item.jobId === order.jobId && item.frameIndex === order.frameIndex) {
            order.pending = parseInt(order.pending) + parseInt(item.assigned);
            if (parseInt(order.assigned) - parseInt(item.assigned) === 0) {
              order.jobStatus = "TOPRINT";
            }
            order.assigned = parseInt(order.assigned) - parseInt(item.assigned);
            return true;
          }
          return false;
        });
      }
    },

    activateOrder: (state, action) => {
      const order = JSON.parse(JSON.stringify(action.payload.order));
      let activeframeIndex = 0;
      order.jobStatus = "TOPRINT";
      order.frameIndex = 0;
      const frames = order.frames;
      frames.forEach((frame) => {
        const copies = frame.copies;
        frame.remaining = copies;
        frame.pending = copies;
        frame.assigned = 0;
      });
      let activeFrame = frames[activeframeIndex];
      const copies = activeFrame.copies;
      const sizeInfo = activeFrame.sizeInfo || order.jobSizeInfo;
      let obj = {
        ...order,
        copies: copies,
        remaining: copies,
        amountPrinted: 0,
        pending: copies,
        assigned: 0,
        thumbnailPath: activeFrame.thumbnailPath,
        filePath: activeFrame.filePath,
        fileType: activeFrame.fileType,
        physicalWidth: parseFloat(sizeInfo.widthMM),
        physicalHeight: parseFloat(sizeInfo.heightMM),
      }
      const orderIndex = state.allOrders.findIndex(
        (item) => (item.orderId === order.orderId)
      );

      if (orderIndex >= 0) {
        state.allOrders.splice(orderIndex + 1, 0, obj);
      } else {
        state.allOrders.push(obj);
      }
    },

    fetchHistoryOrders: (state, action) => {
      let temp = [];
      action.payload.orders.forEach((order) => {
        let jobs = order.jobs;
        let date = Utils.getLocalDate(order.date);
        jobs.forEach((job, index) => {
          let frames = job.frames;
          let mediaInfo = job.mediaInfo;
          let orderId = order.orderId;
          let sizeInfo = job.sizeInfo;
          let dealerOrderId = order.dealerOrderId;
          let activeframeIndex = 0;
          let activeFrame = frames[activeframeIndex];
          let obj = {
            frames: frames,
            id: order.id + job.jobId,
            objId: order.id,
            orderId: orderId,
            dealerOrderId: dealerOrderId,
            jobId: job.jobId,
            jobStatus: job.jobStatus,
            date: date,
            mediaSize: mediaInfo.mediaSize,
            sku: mediaInfo.sku,
            mediaName: mediaInfo.mediaName,
            mediaColor: mediaInfo.mediaColor,
            physicalWidth: parseFloat(sizeInfo.widthMM),
            physicalHeight: parseFloat(sizeInfo.heightMM),
            copies: activeFrame.copies,
            remaining: activeFrame.remaining,
            amountPrinted: parseInt(activeFrame.copies) - parseInt(activeFrame.remaining),
            pending: activeFrame.pending,
            assigned: parseInt(activeFrame.remaining) - parseInt(activeFrame.pending),
            thumbnailPath: activeFrame.thumbnailPath,
            filePath: activeFrame.filePath,
            fileType: activeFrame.fileType,
            jig: job.jig,
            location: job.location,
            qualityLevel: job.qualityLevel,
            printerType: job.printerType,
            mediaType: job.mediaType,
            colorConfig: job.colorConfig
          }
          temp.push(obj);
        });
      });
      state.historyOrders = temp;
    },

    deleteHistoryOrder: (state, action) => {
      const order = action.payload.order;
      const index = state.historyOrders.findIndex(
        (item) => (item.orderId === order.orderId && item.jobId === order.jobId)
      );
      state.historyOrders.splice(index, 1);
    },
  }
});

export const ordersActions = ordersSlice.actions;

export default ordersSlice.reducer;
