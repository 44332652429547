import React from "react";
import Card from "../../../components/Card/Card";
import classes from "./SettingsAction.module.css";

function SettingsAction(props) {
    return (<>
        <Card width="17rem" height="12rem">
            <div className={classes.container}>
                <div className={classes.icon} style={{ width: "3.5rem", height: "3.5rem", backgroundColor: props.iconBackground }} >
                    <img src={props.src} alt={props.altText} />
                </div>
                <div className={classes.title}>{props.title}</div>
            </div>
        </Card>
    </>);
}

export default SettingsAction;