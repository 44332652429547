import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ValueList from "../../components/ValueList/ValueList";
import Actions from "./Actions";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Modal from "../../components/Modal/Modal";
import classes from "./ExtendFilter.module.css";

function getItemValues(item) {
    if (typeof(item.value) === "string") {
        return [item.value];
    }
    return item.value;
}

function ExtendFilter(props) {
    const { t } = useTranslation();
    const [matchRule, setMatchRule] = useState(props.item.matchRule || "EQUAL");
    const [values, setValues] = useState(getItemValues(props.item));
    const [actions, setActions] = useState(props.item.actions);

    function applyChangesHandler() {
        props.item.matchRule = matchRule;
        props.item.value = values;
        props.item.actions = actions;
        props.onClose()
    }

    return (
        <Modal title={t('extendFilter.heading')} onClose={() => props.onClose()}>
            <div className={classes.main}>
                { !props.isDefault &&
                    <div className={classes.leftContainer}>
                        <div className={classes.filterMatchRules}>
                            <label style={{ display: "block" }}>{t('extendFilter.matchRule')}</label>
                            <select value={matchRule} onChange={(e) => setMatchRule(e.target.value)}>
                                <option value="CONTAINS">{t('matchRule.contains')}</option>
                                <option value="BEGINS_WITH">{t('matchRule.beginsWith')}</option>
                                <option value="ENDS_WITH">{t('matchRule.endsWith')}</option>
                                <option value="DOES_NOT_CONTAIN">{t('matchRule.doesNotContain')}</option>
                                <option value="EQUAL">{t('matchRule.equal')}</option>
                                <option value="NOT_EQUAL">{t('matchRule.notEqual')}</option>
                            </select>
                        </div>
                        <div className={classes.filterValues}>
                            <label style={{ display: "block" }}>{t('extendFilter.addValues')}</label>
                            <ValueList values={values} onChange={(values) => setValues(values)} firstItemReadOnly={true}></ValueList>
                        </div>
                    </div>
                }
                <div className={classes.rightContainer}>
                    <div className={classes.filterActions}>
                        <label style={{ display: "block" }}>{t('extendFilter.addActions')}</label>
                        <Actions actions={actions} onChange={(actions) => setActions(actions)} />
                    </div>
                </div>
                <div className={classes.actions}>
                    <SecondaryButton onClick={props.onClose} label={t('button.cancel')} />
                    <PrimaryButton label={t('button.apply')} onClick={applyChangesHandler} />
                </div>
            </div>
        </Modal>
    );
}

export default ExtendFilter;