import React, { useEffect } from 'react';
import './App.css';
import Loading from './components/Loading/Loading';
import Login from './modules/Dashboard/components/Login';
import Dashboard from './modules/Dashboard/components/Dashboard';
import { Redirect, Route, Switch } from 'react-router';
import { fetchProfile } from './modules/Users/UsersActions';
import { useDispatch } from 'react-redux';
import B2BDashboard from './modules/Dashboard/components/B2BDashboard';
import CreateEnvironment from './modules/Dashboard/components/CreateEnvironment';
import { useState } from 'react';
import Modal from './components/Modal/Modal';

// Add format api to JS String.
// Allows formatting strings by replacing placeholders like {0} with the
// provided corresponding argument.
if (!String.format) {
  String.format = function (format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match;
    });
  }
}

function checkLoggedIn() {
  let token = localStorage.getItem("isLoggedIn");
  if (!token) {
    return false;
  }
  return true;
}

window.getResourceName = function () {
  return this.sessionStorage.getItem("resourceName") || '';
}

window.setResourceName = function (rname) {
  return this.sessionStorage.setItem("resourceName", rname);
}

function shouldShowCookieInfo() {
  let prev = window.localStorage.getItem('cookieInfoTime');
  prev = prev ? Number(prev) : 0;
  return Date.now() > (prev + 15552000000);
}

function App() {
  const dispatch = useDispatch();
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookieInfo, setshowCookieInfo] = useState(shouldShowCookieInfo());

  useEffect(() => {
    if (checkLoggedIn()) {
      fetchProfile(dispatch);
    }
  }, [dispatch]);

  return (
    <div className='App'>
      <Loading target={window} />
      <Switch>
        <Route exact path="/" render={routeProps => {
          return checkLoggedIn() ? <Redirect to="/customers" /> : <Login {...routeProps} type="b2b" setShowPrivacy={setShowPrivacy} />
        }} />
        <Route path="/customers" render={routeProps => {
          return checkLoggedIn() ? <B2BDashboard {...routeProps} /> : <Redirect to="/" />
        }} />
        <Route path="/create-environment" render={routeProps => {
          return checkLoggedIn() ? <CreateEnvironment /> : <Login {...routeProps} type="b2c" setShowPrivacy={setShowPrivacy} />
        }} />
        <Route exact path="/:resourceName" render={routeProps => {
          const resourceName = routeProps.match.params.resourceName;
          window.setResourceName(resourceName);
          return checkLoggedIn() ? <Redirect to={resourceName ? `/${resourceName}/orders` : "/"} /> : <Login {...routeProps} type="b2c" setShowPrivacy={setShowPrivacy} />
        }} />
        <Route path="/:resourceName" render={routeProps => {
          const resourceName = routeProps.match.params.resourceName;
          return checkLoggedIn() ? <Dashboard {...routeProps} /> : <Redirect to={`/${resourceName}`} />
        }} />
      </Switch>
      {
        showPrivacy &&
        <Modal onClose={e => setShowPrivacy(false)} style={{ zIndex: 10000000 }}>
          <div className='policyContainer' style={{ marginTop: '2rem' }}>
            <iframe src={`/policy/${localStorage.getItem('lang') || "en"}.html`} />
          </div>
        </Modal>
      }
      {
        showCookieInfo &&
        <div className='cookieInfo'>
          <div>
            <span>The Roland DG websites use cookies for personalised content and ads to provide social media features and to analyse traffic on our website. If you continue using this website, you accept our </span>
            <span className='cookieNotice' onClick={e => setShowPrivacy(true)}>Cookie Notice</span>.
            <span> You can change your cookie settings at any time.</span>
          </div>
          <button onClick={e => {
            window.localStorage.setItem('cookieInfoTime', Date.now());
            setshowCookieInfo(false);
          }}>DON'T SHOW ME THIS MESSAGE AGAIN</button>
        </div>
      }
    </div>
  );
}

export default App;
