import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterLevel from "./FilterLevel";
import FilterUtils from "./FilterUtils";
import CustomParams from "../CustomParams/CustomParams";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Utils from "../../Utils";
import classes from "./FilterTree.module.css";

function getInitialSelection(decisionTree) {
    if (decisionTree.rootFilterNodeId) {
        return [{
            filterNodeId: decisionTree.rootFilterNodeId,
            dataNodeId: null,
        }];
    }
    return [];
}

function FilterTree(props) {
    const { t } = useTranslation();

    const inputSettings = useSelector((state) => state.inputSettings.inputSettings);
    let customParams = inputSettings.customParams || [];
    let filters = Utils.getSystemParameterList();
    filters = filters.concat(customParams.map((item) => item.name));

    let filterNodes = props.decisionTree.filterNodes;
    let dataNodes = props.decisionTree.dataNodes;

    const [selection, setSelection] = useState(getInitialSelection(props.decisionTree));
    const [currentFilter, setCurrentFilter] = useState(filters[0]);
    const [addEnabled, setAddEnabled] = useState(false);
    const [showCustomParams, setShowCustomParams] = useState(false);

    function checkSelectedFilter(currentFilter) {
        // Can add only if the filter is not already used.
        for (let i = 0; i < selection.length; i++) {
            let filterNode = filterNodes[selection[i].filterNodeId];
            if (filterNode.key === currentFilter) {
                return false;
            }
        }

        // And, we have a selected node to add the next filter to.
        if (selection.length !== 0 && !selection[selection.length - 1].dataNodeId) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        setAddEnabled(() => checkSelectedFilter(currentFilter));
    }, [currentFilter])

    useEffect(() => {
        checkAvailableFilter();
    }, [selection]);

    function rowSelectHandler(filterNodeId, dataNodeId) {
        setSelection((prevState) => {
            let temp = [...prevState];
            let index = temp.findIndex((item) => item.filterNodeId === filterNodeId);
            temp.splice(index + 1);
            temp[index].dataNodeId = dataNodeId;

            // See if we have the next level filter. If yes, expand it.
            let filterValue = FilterUtils.getFilterValue(filterNodeId, dataNodeId, filterNodes);
            if (filterValue && filterValue.filterNodeId) {
                temp.push({
                    filterNodeId: filterValue.filterNodeId,
                    dataNodeId: null
                });
            }
            return temp;
        });
    }

    function rowDeleteHandler(filterNodeId, dataNodeId) {
        let index = selection.findIndex((item) => item.filterNodeId === filterNodeId && item.dataNodeId === dataNodeId);
        if (index !== -1) {
            setSelection((prevState) => {
                let temp = [...prevState];
                temp.splice(index + 1);
                temp[index].dataNodeId = null;
                return temp;
            });
        }
    }

    function addFilterHandler() {
        // Create a new filter with the default data node.
        const dataNode = FilterUtils.createDataNode("default", dataNodes);
        const filterNode = FilterUtils.createFilterNode(currentFilter, dataNode.id, filterNodes);

        const filterValue = FilterUtils.getSelectedFilterValue(selection, filterNodes);
        if (filterValue === null) {
            props.decisionTree.rootFilterNodeId = filterNode.id;
        } else {
            filterValue.filterNodeId = filterNode.id;
        }

        setSelection((prevState) => {
            let temp = [...prevState];
            temp.push({
                filterNodeId: filterNode.id,
                dataNodeId: null
            });
            return temp;
        });
        setAddEnabled(() => false);
    }

    function deleteFilterHandler(filterNodeId) {
        var index = selection.findIndex((item) => item.filterNodeId === filterNodeId);
        if (index === -1) {
            return;
        }

        // Deleting the root level.
        if (index === 0) {
            FilterUtils.clearFilterTree(props.decisionTree);
            filterNodes = {};
            dataNodes = {};
            setSelection([]);
            return;
        }

        // Get the previous level.
        const prevFilterNodeId = selection[index - 1].filterNodeId;
        const prevFilterNode = filterNodes[prevFilterNodeId];
        prevFilterNode.values.forEach((filterValue) => {
            if (filterValue.filterNodeId === filterNodeId) {
                // Unlink the sub tree.
                filterValue.filterNodeId = null;
                filterValue.isLinked = false;
                FilterUtils.removeFilterNode(filterNodeId, filterNodes, dataNodes);
            }
        });

        setSelection((prevState) => {
            let temp = [...prevState];
            temp.splice(index);
            return temp;
        });
    }

    function getFilterLevels() {
        const levels = [];
        for (let i = 0; i < selection.length; i++) {
            let filterNode = filterNodes[selection[i].filterNodeId];
            levels.push(
                <FilterLevel key={filterNode.id} filterNodeId={filterNode.id}
                    selected={selection[i].dataNodeId} filterNodes={filterNodes} dataNodes={dataNodes}
                    onRowSelect={rowSelectHandler} onRowDelete={rowDeleteHandler}
                    onDelete={deleteFilterHandler} />
            );
        }
        return levels;
    }

    function getFilterDisplayName(name) {
        if (name.startsWith('systemParam.')) {
            return t(name);
        } else {
            return name;
        }
    }

    function checkAvailableFilter() {
        const isCurrentFilterUsed = selection.some((item) => {
            let filterNode = filterNodes[item.filterNodeId];
            return filterNode.key === currentFilter;
        });
        if (!isCurrentFilterUsed && !(selection.length > 0 && !selection[selection.length - 1].dataNodeId)) {
            setAddEnabled(true);
            return;
        }

        for (let i = 0; i < filters.length; i++) {
            const isFilterUsed = selection.some((item) => {
                let filterNode = filterNodes[item.filterNodeId];
                return filterNode.key === filters[i];
            })
            if (!isFilterUsed && !(selection.length > 0 && !selection[selection.length - 1].dataNodeId)) {
                setCurrentFilter(filters[i]);
                setAddEnabled(true);
                return;
            }
        }
        setAddEnabled(false);
    }

    function closeCustomParamsHandler() {
        setShowCustomParams(false);
    }

    return (<>
        {
            showCustomParams && <CustomParams onClose={closeCustomParamsHandler} onSave={closeCustomParamsHandler} />
        }
        <div className={classes.filterTree}>
            <div>
                <div className={classes.filterField}><h3>{t('filters.addFilter')}</h3></div>
                <div className={classes.filterField}>
                    <select onChange={(ele) => {
                        const currentValue = ele.target.value;
                        if (currentValue === "CUSTOM") {
                            setCurrentFilter(filters[0]);
                            setAddEnabled(checkSelectedFilter(filters[0]));
                            setShowCustomParams(true);
                        } else {
                            setCurrentFilter(currentValue);
                            setAddEnabled(checkSelectedFilter(currentValue));
                        }
                    }} value={currentFilter}>
                        {filters.map((filter) => {
                            let title = getFilterDisplayName(filter);
                            return <option key={filter} value={filter}>{title}</option>
                        })}
                        <option disabled>────────────────────</option>
                        <option key={`CUSTOM-key`}
                            value={"CUSTOM"}>
                            {t('filters.addCustomParameter')}
                        </option>
                    </select>
                    <div className={classes.addBtn}>
                        <PrimaryButton label={t('button.add')} onClick={addFilterHandler} disabled={!addEnabled} />
                    </div>
                </div>
            </div>
            <div className={classes.filterLevel}>
                {getFilterLevels()}
            </div>
        </div>
    </>
    );
}

export default FilterTree;
