import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import Modal from '../../components/Modal/Modal';
import classes from './TableList.module.css';

function TableList(props) {

    const { t } = useTranslation();
    const tables = props.tables;
    const [selectedTables, setSelectedTable] = useState(Array(tables.length).fill(true));

    function saveClickHandler() {
        if (!selectedTables.includes(false)) {
            props.saveJigHandler(true);
        }
        else {
            const tableIds = [];
            tables.forEach((table, index) => {
                if (selectedTables[index]) {
                    tableIds.push(table.id);
                }
            });
            props.updateJigHandler(tableIds);
        }
    }

    return (
        <Modal title={t('tableList.heading')} onClose={props.onClose}>
            <div className={classes.main}>
                <div className={classes.header}>
                    <p>{t('tableList.headerMessage')}</p>
                    <div className={classes.item}>
                        <input type="checkBox" id="checkAll"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedTable((prevState) => {
                                        let temp = Array(tables.length).fill(true);
                                        return temp;

                                    });
                                }
                                else {
                                    setSelectedTable((prevState) => {
                                        let temp = Array(tables.length).fill(false);
                                        return temp;
                                    });
                                }
                            }} checked={!selectedTables.includes(false)} />
                        <label htmlFor="checkAll">{t('tableList.checkAll')}</label>
                    </div>
                </div>
                <div className={classes.container}>
                    {tables.map((table, index) => {
                        return <div key={table.id} className={classes.item}>
                            <input type="checkBox" id={table.id} onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedTable((prevState) => {
                                        let temp = [...prevState];
                                        temp[index] = true;
                                        return temp;

                                    });
                                }
                                else {
                                    setSelectedTable((prevState) => {
                                        let temp = [...prevState];
                                        temp[index] = false;
                                        return temp;

                                    });
                                }
                            }} checked={selectedTables[index]} />
                            <label htmlFor={table.id}>{table.name}</label>
                        </div>
                    })}
                </div>
                <div className={classes.actions}>
                    <SecondaryButton label={t('button.cancel')} onClick={props.onClose} />
                    <PrimaryButton label={t('button.ok')} onClick={saveClickHandler} />
                </div>
            </div>
        </Modal>
    );
}

export default TableList;