import React, { useState } from "react";
import OutputSettingSubContainer from "../OutputSettings/OutputSettingSubContainer";
import IconButton from "../../components/Buttons/IconButton";
import arrowUpImage from "../../assets/arrow-up.svg";
import arrowDownImage from "../../assets/arrow-down.svg";
import classes from "./OutputPreset.module.css"
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
export function Select({label, options, onChange, value, defaultOption = true}) {
    const {t} =  useTranslation();
    return <div>
        {label && <div style={{fontSize:"0.75rem"}}>{label}</div>}
        <select value={value} onChange={e => onChange && onChange(e.target.value)} className={classes.selectMenu}>
            {defaultOption && <option value>--{t("select")}--</option>}
            {options && options.map((opt, idx) => 
                <option key={idx} value={opt.value}>{opt.label} </option>
            )}
        </select>
    </div>
}

function defaultComparator(a, b) {
    return a === b;
}

export default function SettingBox({title, items = [], onUp, onDown, onReload, children, addButtonLabel, onAddButton, ItemComponent, selectedItem, containerStyle, style, disableAddButton, ItemsWrapper = React.Fragment, itemComparator = defaultComparator}) {
    const [selection, setSelection] = useState(selectedItem);
    useEffect(() => {
        setSelection(selectedItem);
    }, [selectedItem]);
    
    const selectedIdx = items.findIndex(i => itemComparator(i, selection));
    return <OutputSettingSubContainer containerStyle={containerStyle}
        headingChildren={<>
            <h3 className={classes.settingBoxHeading}>
                <span>{title}</span>
                {onReload && <span className="fa fa-refresh" onClick={onReload}/>}
            </h3>
            {onUp && <IconButton iconImage={arrowUpImage} onClick={e => onUp(selection, selectedIdx)}
                disabled={selectedIdx <= 0}
            />}
            {onDown && <IconButton iconImage={arrowDownImage} onClick={e => onDown(selection, selectedIdx)}
                disabled={selectedIdx < 0 || selectedIdx >= (items.length - 1)}
            />}
        </>}
        withAddButton={Boolean(onAddButton)}
        addButtonLabel={addButtonLabel || '+Add'}
        disableAddButton={disableAddButton}
        addButtonClickHandler={onAddButton}
        style={style}
    >
        <ItemsWrapper>
        {
            items.map((item, index) => 
                <ItemComponent key={index} item={item} index={index}
                    selected={itemComparator(selection, item)} 
                    onSelectionChange={e => setSelection(item)}
                />
            )
        }
        </ItemsWrapper>
        {children}
    </OutputSettingSubContainer>
}