import axios from '../../axios';
import { tablesActions } from "./TablesSlice";

export function getAllTables(dispatch) {
    return axios({
        url: '/pax/table',
        method: 'get',
        responseType: 'json'
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(tablesActions.fetchTables({ tables: data.tables || [] }));
        }
    }).catch(function (error) {
        console.log(error);
    });
}

export function getUserTables(dispatch) {
    return axios({
        url: '/pax/user/tables',
        method: 'get',
        responseType: 'json'
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(tablesActions.fetchUserTables({ tables: data.tables || [] }));
        }
    }).catch(function (error) {
        console.log(error);
    });
}

export function updateTable(dispatch, data) {
    return axios({
        url: "/pax/table",
        method: "put",
        data: data,
        responseType: "json"
    }).then((response) => {
        if (response.data.success) {
            dispatch(tablesActions.updateTable({ table: data }));
        }
        return response;
    }).catch(function (error) {
        return error;
    });
}

export function deleteTable(dispatch, id) {
    return axios({
        url: "/pax/table",
        method: "delete",
        data: {
            id: id
        },
        responseType: "json"
    }).then((response) => {
        if (response.data.success) {
            dispatch(tablesActions.deleteTable({ id: id }));
        }
        return response;
    }).catch(function (error) {
        return error;
    });
}

export function addTable(dispatch, data) {
    return axios({
        url: "/pax/table",
        method: "post",
        data: data,
        responseType: "json"
    }).then((response) => {
        if (response.data.success) {
            let newdata = data;
            newdata.id = response.data.id;
            dispatch(tablesActions.createTable({ table: newdata }));
        }
        return response;
    }).catch(function (error) {
        return error;
    });
}

export function updateTableList(dispatch, data) {
    return axios({
        url: "/pax/table/update",
        method: "put",
        data: data,
        responseType: "json"
    }).then((response) => {
        if (response && response.data && response.data.success) {
            dispatch(tablesActions.updateTableList({ data: data }));
        }
        return response;
    }).catch(function (error) {
        return error;
    });
}