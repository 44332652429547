import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Tabs.module.css";

function Tab(props) {
    let tabItem = `${classes.tabItem}`;
    if (props.isActive) {
        tabItem += ` ${classes.tabItemActive}`;
    }

    function onClick() {
        props.onClick(props.index);
    }

    if (props.link) {
        return (
            <Link to={props.link} className={classes.tabItemLink}>
                <li className={tabItem} onClick={onClick}>
                    {props.label}
                </li>
            </Link>);
    }
    else {
        return (
            <li className={tabItem} onClick={onClick}>
                {props.label}
            </li>);
    }
}

function Tabs(props) {
    const [activeTab, setActiveTab] = useState(Number(props.activeTab) || 0);

    useEffect(() => {
        setActiveTab(Number(props.activeTab));
    }, [props.activeTab]);

    function onClickTabItem(tabIndex) {
        setActiveTab(tabIndex);
    }

    return (
        <div className={classes.tabs}>
            <ol className={classes.tabList}>
                {props.children.map((child, index) => {
                    const { label, link } = child.props;
                    return (
                        <Tab
                            isActive={index === activeTab}
                            key={label}
                            index={index}
                            label={label}
                            onClick={onClickTabItem}
                            link={link}
                        />
                    );
                })}
            </ol>
            <div className={classes.tabContent}>
                {props.children.map((child, index) => {
                    if (index !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
}

export default Tabs;
