import React, { useEffect, useState } from "react";
import TextButton from "../Buttons/TextButton";
import AddValue from "./AddValue";
import ValueListNode from "./ValueListNode";
import classes from "./ValueList.module.css";
import { t } from "i18next";

export default function(props) {
    const [values, setValues] = useState(props.values || []);
    const [showAddValue, setShowAddValue] = useState(false);
    const disallowedValues = values.map((value) => value.toLowerCase());

    useEffect(() => {
        setValues(props.values || []);
    }, [props.values]);

    useEffect(() => {
        props.onChange && props.onChange(values);
    }, [values]);

    function addValueHandler(newValue) {
        if (!values.find(value => value.toLowerCase() === newValue.toLowerCase())) {
            setValues((prevState) => [...prevState, newValue]);
        }
        setShowAddValue(false);
    }

    function getHeight() {
        if (props.showHeader && showAddValue) {
            return 'calc(100% - 10rem)';
        } else if (showAddValue) {
            return 'calc(100% - 7.5rem)';
        } else if (props.showHeader) {
            return 'calc(100% - 5rem)';
        } else {
            return 'calc(100% - 2.5rem)';
        }
    }

    return (
        <div className={classes.values} style={props.style}>
            {props.showHeader && <div className={classes.valueListHeader}>
                <h3>{props.title}</h3>
            </div>}
            {showAddValue &&
                <AddValue onAdd={addValueHandler}
                    onCancel={e => setShowAddValue(false)}
                    validator={props.validator}
                    disallowedValues={disallowedValues}
                    suggestions={props.suggestions} />
            }
            <div className={classes.valueList}
                style={{height : getHeight()}}>
                <ol>
                    {
                        values.map((value, index) =>
                            <ValueListNode key={value} value={value}
                                onDelete={e => setValues(prevState => {
                                    let v = [...prevState];
                                    v.splice(index, 1);
                                    return v;
                                })}
                                onEdit={updatedValue => setValues(prevState => {
                                    let v = [...prevState]
                                    v[index] = updatedValue;
                                    return v;
                                })}
                                validator={props.validator}
                                readOnly={props.firstItemReadOnly && (index === 0)}
                            />
                        )
                    }
                </ol>
            </div>
            <div className={classes.valueListFooter}>
                <div className={classes.addValueBtn}>
                    <TextButton label={t('button.addValue')} onClick={e => setShowAddValue(true)}
                        disabled={props.maxSize && values.length >= props.maxSize} />
                </div>
            </div>
        </div>
    );
}