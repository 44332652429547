import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../Buttons/SecondaryButton";
import TextButton from "../Buttons/TextButton";
import classes from "./AddValue.module.css";
import AutoComplete from "../AutoComplete/AutoComplete";

function AddValue(props) {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const [addEnabled, setAddEnabled] = useState(false);
    const [showError, setShowError] = useState(false);
    const inputReference = useRef();

    useEffect(() => {
        if (inputReference && inputReference.current) {
            inputReference.current.focus();
        }
    }, []);

    function valueChangedHandler(value) {
        setValue(value);
        const newValue = value.trim();
        if (newValue === "") {
            setAddEnabled(false);
            setShowError(false);
        }
        else {
            if (props.validator && !props.validator(newValue)) {
                setAddEnabled(false);
                setShowError(true);
            }
            else if (props.disallowedValues && props.disallowedValues.includes(newValue.toLowerCase())) {
                setAddEnabled(false);
                setShowError(true);
            }
            else {
                setShowError(false);
                setAddEnabled(true);
            }
        }
    }

    function addValueHandler() {
        props.onAdd(value.trim());
    }

    const hasSuggestions = props.suggestions && props.suggestions.length !== 0;
    return (<div className={classes.addValue}>
        <div>{hasSuggestions ?
            <AutoComplete suggestions={props.suggestions} valueChangedHandler={valueChangedHandler} /> :
            <input type="text"
                name="value"
                onChange={(e) => {
                    const value = e.target.value;
                    valueChangedHandler(value);
                }}
                defaultValue={value}
                autoComplete="off"
                ref={inputReference} />
        }
        </div>
        {showError && <div><span style={{ "color": "red", "marginLeft": "0.7rem" }}> {t('addValue.error.alreadyExists')}</span></div>}
        <div className={classes.buttons}>
            <div className={classes.addValueButton}>
                <SecondaryButton onClick={addValueHandler} label={t('button.add')} disabled={!addEnabled} />
            </div>
            <TextButton onClick={props.onCancel} label={t('button.cancel')} />
        </div>
    </div>);
}

export default AddValue;