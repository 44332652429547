import React from "react";
import classes from './TextButton.module.css';

function TextButton({onClick, disabled, style, label}) {
    const textBtn = `btn btn-md ${classes.textBtn}`;
    return (
        <button type="button" onClick={onClick} disabled={disabled}
            style={style} className={textBtn}>
            {label}
        </button>
    );
}

export default TextButton;