import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal/Modal";
import PrimaryButton from "../../components/Buttons/PrimaryButton"
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import classes from "./LinkWith.module.css";

function getInitialSelected(linkValues) {
    return new Array(linkValues.length).fill(false);
}

function getNodeValue(node) {
    if (typeof (node.value) === "object") {
        return node.value[0];
    } else {
        return node.value;
    }
}

function LinkWith(props) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(getInitialSelected(props.linkValues));

    function hasSelection(selection) {
        return selection.some(item => item);
    }

    function checkedChangeHandler(e) {
        let index = Number(e.target.id);
        setSelected((prevState) => {
            let temp = new Array(prevState.length).fill(false);
            temp[index] = e.target.checked;
            return temp;
        });
    }

    function applyChangesHandler() {
        let selectedValues = [];
        selected.forEach((value, index) => {
            if (value) {
                selectedValues.push(props.linkValues[index]);
            }
        });
        props.onApply(selectedValues);
    }

    function getTitleForValue(linkValue) {
        return linkValue.map((item) => {
            let node = props.dataNodes[item.dataNodeId];
            return getNodeValue(node);
        }).join(", ");
    }

    return (
        <Modal title={t('linkWith.heading')} onClose={() => props.onClose()}>
            <div className={classes.main}>
                <div className={classes.linkWithList}>
                    <label style={{ display: "block" }}>{String.format(t('linkWith.subheading'), props.name)}</label>
                    <div className={classes.list}>
                        {props.linkValues.map((linkValue, index) => {
                            let title = getTitleForValue(linkValue);
                            let id = index.toString();
                            return (
                                <div className={classes.listItem} key={index}>
                                    <input type="checkbox" id={id} value={title} checked={selected[index]} onChange={checkedChangeHandler} />
                                    <div className={classes.label}>
                                        <label htmlFor={id}>{title}</label>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={classes.actions}>
                    <SecondaryButton onClick={props.onClose} label={t('button.cancel')} />
                    <PrimaryButton label={t('button.apply')} disabled={!hasSelection(selected)} onClick={applyChangesHandler} />
                </div>
            </div>
        </Modal>
    );
}

export default LinkWith;
