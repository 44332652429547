import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ValueList from '../../components/ValueList/ValueList';
import Utils from '../../Utils';
import classes from './CustomParamsData.module.css';

function valueInList(valueArr, value) {
    value = value.toLowerCase();
    return valueArr.some((item) => item.toLowerCase() === value);
}

function CustomParamsData(props) {

    const customParams = props.customParams;
    const { t } = useTranslation();

    const initialMediaList = customParams.filter(i => i.type === 'media').map(i => i.name);
    const initialTopLevelList = customParams.filter(i => i.type === 'topLevel').map(i => i.name);
    const [mediaList, setMediaList] = useState(initialMediaList || []);
    const [topLevelList, setTopLevelList] = useState(initialTopLevelList || []);

    useEffect(() => {
        const userData = mediaList.map(i => ({ name: i, type: "media" })).concat(topLevelList.map(i => ({ name: i, type: "topLevel" })));
        props.onChange && props.onChange(userData);
    }, [mediaList, topLevelList])

    function validator(value) {
        if (!value ||
            valueInList(topLevelList, value) ||
            valueInList(mediaList, value) ||
            valueInList(Utils.getSystemParameterList().map(item => t(item)).filter(val => val), value)
        ) {
            return false;
        }
        return true;
    }

    const topLevelListStyle = { "width" : "18rem" };

    return (<div className={classes.main}>
        <ValueList values={topLevelList} maxSize={5} 
            onChange={(data) => setTopLevelList(data)} validator={validator} style={topLevelListStyle} />
        {/* For the time being media list is not used. */}
        {/* <ValueList values={mediaList} maxSize={5} title={t("customParams.media")}
            onChange={(data) => setMediaList(data)} validator={validator} style={{ marginLeft: "1rem" }}
            showHeader /> */}
    </div>);
}

export default CustomParamsData;