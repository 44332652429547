import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateDecisionTree } from "./FiltersActions";
import Utils from "../../Utils";
import Modal from "../../components/Modal/Modal";
import FilterTree from "./FilterTree";
import FilterUtils from "./FilterUtils";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import classes from "./Filters.module.css";

function Filters(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");
    
    let decisionTree = useSelector((state) => state.filters.allFilters.decisionTree, shallowEqual);
    decisionTree = JSON.parse(JSON.stringify(decisionTree)); // Deep clone the tree to be able to make changes

    if (decisionTree.rootFilterNodeId === undefined) {
        decisionTree = { ...decisionTree, filterNodes: [], dataNodes: [], rootFilterNodeId: null };
    }

    decisionTree.filterNodes = FilterUtils.createNodesMap(decisionTree.filterNodes || []);
    decisionTree.dataNodes = FilterUtils.createNodesMap(decisionTree.dataNodes || []);

    function saveFiltersHandler() {
        const saveTree = {
            rootFilterNodeId: decisionTree.rootFilterNodeId,
            filterNodes: FilterUtils.mapToArray(decisionTree.filterNodes),
            dataNodes: FilterUtils.mapToArray(decisionTree.dataNodes)
        };

        updateDecisionTree(dispatch, saveTree).then((response) => {
            if (response && response.data && response.data.success) {
                props.onClose();
                setShowError(false);
            }
            else {
                setShowError(true);
            }
        });
    }

    function closeErrorHandler() {
        setShowError(false);
    }

    function isSaveDisabled() {
        if (Utils.canEditAutomationRules(permissions)) {
            return false;
        }

        return true;
    }

    return (
        <>
            {showError && Utils.showErrorDialog(t('filters.error.saveFailed'), closeErrorHandler)}
            <Modal title={t('filters.heading')} onClose={props.onClose}>
                <div className={classes.main}>
                    <FilterTree decisionTree={decisionTree} />
                    <div className={classes.action}>
                        <SecondaryButton onClick={props.onClose} label={t('button.cancel')} />
                        <PrimaryButton label={t('button.save')} onClick={saveFiltersHandler} disabled={isSaveDisabled()}/>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Filters;