import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Settings from '../../Dashboard/components/Settings';
import Jigs from '../../Jigs/Jigs';
import Tables from '../../Tables/Tables';
import Config from '../../Config/Config';
import Connectors from '../../Connectors/Connectors';
import Orders from '../../Orders/components/Orders';
import Users from '../../Users/Users';
import Utils from '../../../Utils';
import Header from '../../../components/Header/Header';
import { logoutUser } from '../../Auth/AuthActions';
import { useEffect } from 'react';
import { fetchProfile } from '../../Users/UsersActions';
import { useDispatch, useSelector } from 'react-redux';

import { getAllJigs } from '../../Jigs/JigsActions';
import { getAllTables, getUserTables } from '../../Tables/TablesActions';
import { getAllOrders, getHistoryOrders } from '../../Orders/OrdersActions';
import { getConfig } from '../../Config/ConfigActions';
import { getAllFilters } from '../../Filters/FiltersActions';
import { getAllUsers } from '../../Users/UsersActions';
import { getAllOutputChannels, getOutputChannels } from '../../Orders/OutputChannelActions';
import { getAllOutputPresets, getOutputPresets } from '../../Orders/OutputPresetActions';
import { getAllMasters } from '../../Master/MasterActions';
import { getAllMyPrinters, getAllPrinters } from '../../OutputSettings/OutputSettingsActions';
import { getInputSettings } from '../../InputSettings/InputSettingsActions';

function UserInfo(props) {
    const currentUser = useSelector((state) => state.users.allUsers.currentUser);
    const permissions = useSelector((state) => state.users.allUsers.currentUser.permissions || "");
    const {redirectUrl} = props;
    if (currentUser?.userId) {
        return Utils.canManageUsers(permissions) ? <Users {...props} /> : <Redirect to={redirectUrl} />
    } else {
        return '';
    }
}

export default function ({ match: { url } }) {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.users.allUsers.currentUser) || {};

    useEffect(() => {
        fetchProfile(dispatch);
        getAllJigs(dispatch);
        getAllTables(dispatch);
        getAllMasters(dispatch);
        getConfig(dispatch);
        getUserTables(dispatch);
        getInputSettings(dispatch);
        getAllUsers(dispatch);
        getAllFilters(dispatch);
        getAllOrders(dispatch);
        getHistoryOrders(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (currentUser.role) {
            if (Utils.isAdminOrSuperAdminOrB2bRemoteOrB2cRemoteUser(currentUser)) {
                getAllOutputChannels(dispatch);
                getAllOutputPresets(dispatch);
                getAllPrinters(dispatch);
            }
            else {
                getOutputChannels(dispatch);
                getOutputPresets(dispatch);
                getAllMyPrinters(dispatch);
            }
        }
    }, [currentUser.role]);

    useEffect(() => {
        const dataLayer = window.dataLayer;
        const userId = currentUser.userId;
        if (dataLayer && userId) {
            dataLayer.push({
                event: 'screenAccessed',
                environment: window.getResourceName(),
                screen: url,
                userId
            });
        }
    }, [url, currentUser]);

    return <div>
        <Header logoutUser={logoutUser} isAuth={true} url={url} isB2c={true} />
        <Switch>
            <Route exact path={`${url}/settings`} component={Settings} />
            <Route exact path={`${url}/settings/jigs`} component={Jigs} />
            <Route exact path={`${url}/settings/layouts`} component={Tables} />
            <Route exact path={`${url}/settings/configuration-settings`} component={Config} />
            <Route exact path={`${url}/settings/connectors`} component={Connectors} />
            <Route exact path={`${url}/orders`} component={Orders} />
            <Route exact path={`${url}/settings/users`} render={(routeProps) => <UserInfo {...routeProps} redirectUrl={url} />} />
        </Switch>
    </div>
}
