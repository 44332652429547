import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import customStyles from './MultiSelectStyles';

function MultiSelect(props) {
    const [value, setValue] = useState([]);
    const options = props.options || [];
    const selectedItems = props.value || [];

    useEffect(() => {
        const map = new Map();
        options.forEach(item => map.set(item.value, item));
        setValue(selectedItems.map(item => map.get(item)).filter(val => val));
    }, [selectedItems, options]);

    return (
        <Select
            isMulti
            styles={customStyles}
            closeMenuOnSelect={false}
            value={value}
            onChange={val => {
                setValue(val);
                props.onChange && props.onChange(val.map(item => item.value));
            }}
            options={options}
            placeholder={""}
            noOptionsMessage={() => ""}
            isClearable={false}
        />
    );
}

export default MultiSelect;