import axios from '../../axios';
import { jigsActions } from "./JigsSlice";

export function getAllJigs(dispatch) {
  return axios({
    url: "/pax/jig",
    method: "get",
    responseType: "json",
  })
    .then(function (response) {
      let data = response.data;
      if (data.success) {
        dispatch(jigsActions.fetchJigs({ jigs: data.jigs || [] }));
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function updateJig(dispatch, data) {
  return axios({
    url: "/pax/jig",
    method: "put",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(jigsActions.updateJig({ jig: data }));
      }
      return response;
    }).catch((error) => {
      return error;
    });
}

export function deleteJig(dispatch, id) {
  return axios({
    url: "/pax/jig",
    method: "delete",
    data: {
      id: id,
    },
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(jigsActions.deleteJig({ id: id }));
      };
      return response;
    }).catch((error) => {
      return error;
    });
}

export function addJig(dispatch, data) {
  return axios({
    url: "/pax/jig",
    method: "post",
    data: data,
    responseType: "json",
  })
    .then((response) => {
      if (response.data.success) {
        let newdata = data;
        newdata.id = response.data.id;
        dispatch(jigsActions.createJig({ jig: newdata }));
      }
      return response;
    }).catch((error) => {
      return error;
    });
}
