import React, { useState, useEffect } from "react";
import i18n from '../../../i18n/i18n';
import classes from './LanguageSelector.module.css';
const languagePreference = [
    {label:"Deutsch", val:"de"},
    {label: "English", val:"en"},
    {label:"Español", val:"es"},
    {label:"Français", val:"fr"},
    {label:"Italiano", val:"it"},
    {label:"Japanese", val:"ja"},
    {label:"Polski", val:"pl"},
    {label:"Português", val:"pt"},
];
function LanguageSelector() {
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        let language = localStorage.getItem('lang');
        language = language || 'en';
        switchLanguage(language);
    }, []);

    const switchLanguage = (language) => {
        setLanguage(language);
        localStorage.setItem('lang', language);
        i18n.changeLanguage(language);
    };

    const languageChangeHandler = (event) => {
        switchLanguage(event.target.value);
    };

    return (
        <div className={classes.languageSelector}>
            <span className={`${classes.languageIcon} fa fa-flag`}></span>
            <select className={classes.languageSelect} onChange={languageChangeHandler} value={language}>
            {languagePreference.map((langPref,index)=>( <option key={index} value={langPref.val}>{langPref.label}</option>))}
            </select>
        </div>
    );
}

export default LanguageSelector;
