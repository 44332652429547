import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    outputSettings: {
        myPrinters: []
    },
};

const outputSettingsSlice = createSlice({
    name: "outputSettings",
    initialState,
    reducers: {
        fetchMyPrinters: (state, action) => {
            state.outputSettings.myPrinters = action.payload.myPrinters;
        },
        updateMyPrinters: (state, action) => {
            state.outputSettings.myPrinters = action.payload.myPrinters;
        }
    }
}
);

export const outputSettingsActions = outputSettingsSlice.actions;

export default outputSettingsSlice.reducer;
