import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    fetchCustomers: (state, action) => {
      state.customers = action.payload;
    }
  },
});

export const customersActions = customersSlice.actions;

export default customersSlice.reducer;
